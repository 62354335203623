import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg';
import { fromTheme } from '../../utils';
import messages from './OffCanvas.messages';

type OffCanvasSide = 'left' | 'right';

interface OffCanvasProps {
    children: any;
    isOpen?: boolean;
    side?: OffCanvasSide;
    onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    headline?: React.ReactChild;
}

interface OffCanvasPropsInitialised extends OffCanvasProps {
    side: OffCanvasSide;
    isOpen: boolean;
}

const Wrapper = styled('aside')<OffCanvasPropsInitialised>`
    width: 300px;
    position: fixed;
    top: 0;
    bottom: 0;
    background: ${fromTheme('colors', 'grey500')};
    visibility: hidden;
    transition: all 0.3s;
    z-index: 1000;
    max-height: 100vh;
    overflow: scroll;

    ${(props) =>
        props.isOpen &&
        css`
            visibility: visible;
        `}

    ${(props) =>
        props.side === 'left' &&
        css`
            right: auto;
            left: ${props.isOpen ? '0' : '-300px'};
        `}

    ${(props) =>
        props.side === 'right' &&
        css`
            left: auto;
            right: ${props.isOpen ? '0' : '-300px'};
        `}
`;

const CloseButton = styled('button')`
    background: ${fromTheme('colors', 'grey500')};
    text-align: left;
    padding-left: 20px;
    display: block;
    height: 60px;
    width: 100%;
    border: none;
    cursor: pointer;
    outline: none;
`;

const Headline = styled('span')`
    background: ${fromTheme('color', 'base')};
    color: ${fromTheme('colors', 'white')};
    min-height: 60px;
    padding: 0 20px;
    display: flex;
    font-weight: ${fromTheme('fontWeight', 'base')};
    font-size: ${fromTheme('fontSize', 'base')};
    align-items: center;
`;

const OffCanvas: React.FC<OffCanvasProps> = ({
    children,
    onClose,
    headline,
    side = 'left',
    isOpen = false,
    ...props
}: OffCanvasProps) => {
    const intl = useIntl();

    return (
        <Wrapper isOpen={isOpen} side={side} {...props}>
            <CloseButton
                onClick={onClose}
                title={intl.formatMessage(messages.closeButtonTitle)}
                aria-label={intl.formatMessage(messages.closeButtonTitle)}
                data-is-visible={isOpen}
            >
                <BackArrow />
            </CloseButton>

            {headline && <Headline>{headline}</Headline>}

            {children}
        </Wrapper>
    );
};

export default OffCanvas;
