import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { ReactComponent as Mail } from '../../assets/icons/mail.svg';
import { ReactComponent as Linkedin } from '../../assets/icons/linkedin.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { breakpoints, fromTheme } from '../../utils';
import { Container } from '../container/PageContainer/PageContainer';
import routes from '../../routes.messages';
import messages from './Footer.messages';
import { Link } from 'react-router-dom';

const StyledFooter = styled('footer')`
    background-color: ${fromTheme('colors', 'blue100')};
    font-size: ${fromTheme('fontSize', 'footer')};
    overflow: hidden;
`;

const FooterInnerContainer = styled(Container)`
    display: inline-block;
    margin: 35px auto 35px 50%;
    transform: translateX(-50%);
`;

const MetaNavList = styled('ul')`
    margin: 0 0 25px 0;
    list-style: none;
    columns: 2;
    display: block;

    @media (min-width: ${breakpoints.md}px) {
        columns: 1;
        display: flex;

        > li {
            margin: 0 40px 0 0;
        }
    }
`;

const ExternalLink = styled('a')`
    color: ${fromTheme('colors', 'blue200')};
    font-family: ${fromTheme('fontFamily', 'base')};
    font-weight: ${fromTheme('fontWeight', 'extraBold')};
    text-decoration: none;
    margin-bottom: 18px;
    display: block;
    transition: color 0.2s;

    &:hover {
        color: ${fromTheme('colors', 'white')};
    }
`;

const InternalLink = styled(Link)`
    color: ${fromTheme('colors', 'blue200')};
    font-family: ${fromTheme('fontFamily', 'base')};
    font-weight: ${fromTheme('fontWeight', 'extraBold')};
    text-decoration: none;
    margin-bottom: 18px;
    display: block;
    transition: color 0.2s;

    &:hover {
        color: ${fromTheme('colors', 'white')};
    }
`;

const SocialNavList = styled('ul')`
    display: flex;
    list-style: none;
    margin: 0;
`;

const SocialNavListItem = styled('li')`
    margin: 0 27px 0 0;
`;

const StyledIcon = styled('svg')`
    color: ${fromTheme('colors', 'blue200')};
    transition: color 0.2s;

    &:hover {
        color: ${fromTheme('colors', 'white')};
    }
`;

const socialLinks = [
    {
        href: 'mailto:myrequest@oetker-gruppe.de',
        targetBlank: true,
        icon: Mail,
    },
    {
        href: 'https://www.linkedin.com/company/draugustoetkerkg/?viewAsMember=true',
        targetBlank: true,
        icon: Linkedin,
    },
    {
        href: 'https://twitter.com/Oetker_Gruppe',
        targetBlank: true,
        icon: Twitter,
    },
];

const Footer: React.FC = (props) => {
    const intl = useIntl();

    return (
        <StyledFooter {...props}>
            <FooterInnerContainer>
                <nav aria-label={intl.formatMessage(messages.footerNavLabel)}>
                    <MetaNavList>
                        <li>
                            <ExternalLink
                                href={intl.formatMessage(
                                    messages.footerNavItemComplianceHref
                                )}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <FormattedMessage
                                    {...messages.footerNavItemCompliance}
                                />
                            </ExternalLink>
                        </li>
                        <li>
                            <ExternalLink
                                href={intl.formatMessage(
                                    messages.footerNavItemDataProtectionHref
                                )}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <FormattedMessage
                                    {...messages.footerNavItemDataProtection}
                                />
                            </ExternalLink>
                        </li>
                        <li>
                            <InternalLink
                                to={`/${intl.locale}/${intl.formatMessage(
                                    routes.cookieSettings
                                )}`}
                            >
                                <FormattedMessage
                                    {...messages.footerNavItemCookieSettings}
                                />
                            </InternalLink>
                        </li>
                        <li>
                            <ExternalLink
                                href={intl.formatMessage(
                                    messages.footerNavItemLegalHref
                                )}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <FormattedMessage
                                    {...messages.footerNavItemLegal}
                                />
                            </ExternalLink>
                        </li>
                        <li>
                            <ExternalLink
                                href={intl.formatMessage(
                                    messages.footerNavItemImprintHref
                                )}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <FormattedMessage
                                    {...messages.footerNavItemImprint}
                                />
                            </ExternalLink>
                        </li>
                    </MetaNavList>
                </nav>
                <nav>
                    <SocialNavList>
                        {socialLinks.map((item) => {
                            return (
                                <SocialNavListItem key={item.href}>
                                    {item.targetBlank ? (
                                        <a
                                            href={item.href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <StyledIcon as={item.icon} />
                                        </a>
                                    ) : (
                                        <a href={item.href}>
                                            <StyledIcon as={item.icon} />
                                        </a>
                                    )}
                                </SocialNavListItem>
                            );
                        })}
                    </SocialNavList>
                </nav>
            </FooterInnerContainer>
        </StyledFooter>
    );
};

export default Footer;
