import React from 'react';
import Helmet from 'react-helmet';

interface PageHeadProps {
    pageTitle: string;
    metaDescription: string;
}

const PageHead: React.FC<PageHeadProps> = ({
    pageTitle,
    metaDescription,
    ...props
}) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>
            <meta name="description" content={metaDescription} />
        </Helmet>
    );
};

export default PageHead;
