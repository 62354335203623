import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import CompanyLogo from '../../../assets/images/company-logos/oediv-x2.png';
import stagePathMobile from '../../../assets/images/oediv/stage/oediv-1x.jpg';
import stagePathTablet from '../../../assets/images/oediv/stage/oediv-2x.jpg';
import BenefitList from '../../../components/BenefitList/BenefitList';
import {
    FlexContainer,
    FlexItem,
} from '../../../components/container/FlexContainer/FlexContainer';
import { Container } from '../../../components/container/PageContainer/PageContainer';
import Section from '../../../components/container/Section/Section';
import Headline from '../../../components/Headline/Headline';
import Picture from '../../../components/Picture/Picture';
import Stage from '../../../components/Stage/Stage';
import { useJobData } from '../../../provider/JobDataProvider/JobDataProvider';
import { Benefits, CompanyDetailProps } from '../../../types/common';
import { breakpoints } from '../../../utils';
import JobSection from '../../../components/JobSection/JobSection';
import messages from '../CompanyDetail.messages';
import PageHead from '../../../components/base/PageHead/PageHead';
import messagesCompanySpecific from './Oediv.messages';

const FlexItemCenterContent = styled(FlexItem)`
    text-align: center;
`;

const LogoImage = styled(Picture)`
    max-width: 230px;
    margin: 0 auto 25px;
    display: inline-block;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 0;
        display: block;
    }
`;

const StyledH2Headline = styled(Headline)`
    word-break: break-word;
    margin-bottom: 25px;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 45px;
        word-break: initial;
    }
`;

const Oediv: React.FC<CompanyDetailProps> = ({ companyId, ...props }) => {
    const intl = useIntl();
    const { allJobs, getCompanyJobs } = useJobData();
    const { sortedJobs } = getCompanyJobs(allJobs, companyId);
    const companyBenefits: Benefits[] = [
        'flexibleWorkingHours',
        'homeoffice',
        'employeeRestaurant',
        'foodAllowance',
        'companyPensionScheme',
        'companyDoctor',
        'healthPromotionMeasures',
        'parking',
        'goodTrafficConnections',
        'employeeEvents',
        'employeeDiscounts',
        'employeeMobilePhone',
        'trainings',
        'personalFeedback',
        'coaching',
    ];

    return (
        <>
            <PageHead
                pageTitle={intl.formatMessage(
                    messagesCompanySpecific.pageTitle
                )}
                metaDescription={intl.formatMessage(
                    messagesCompanySpecific.metaDescription
                )}
            />
            <Stage
                borderColor="oetkerDigital"
                imgPathMobile={stagePathMobile}
                imgPathTablet={stagePathTablet}
                alt="Dr. Oetker"
            />
            <Section background="white">
                <Container>
                    <FlexContainer columnType="2-1" reverseDesktop>
                        <FlexItemCenterContent>
                            <LogoImage
                                imgPathMobile={CompanyLogo}
                                alt={intl.formatMessage(messages.logoAlt)}
                            />
                        </FlexItemCenterContent>
                        <FlexItem>
                            <Headline as="h1">
                                <FormattedMessage
                                    {...messagesCompanySpecific.headline}
                                />
                            </Headline>
                            <p>
                                <FormattedHTMLMessage
                                    {...messagesCompanySpecific.section1Text1}
                                />
                            </p>
                            <p>
                                <FormattedHTMLMessage
                                    {...messagesCompanySpecific.section1Text2}
                                />
                            </p>
                            <p>
                                <FormattedHTMLMessage
                                    {...messagesCompanySpecific.section1Text3}
                                />
                            </p>
                            <p>
                                <FormattedHTMLMessage
                                    {...messagesCompanySpecific.section1Text4}
                                />
                            </p>
                            <p>
                                <FormattedHTMLMessage
                                    {...messagesCompanySpecific.section1Text5}
                                />
                            </p>
                            <p>
                                <FormattedHTMLMessage
                                    {...messagesCompanySpecific.section1Text6}
                                />
                            </p>
                            <p>
                                <FormattedHTMLMessage
                                    {...messagesCompanySpecific.section1Text7}
                                />
                            </p>
                        </FlexItem>
                    </FlexContainer>
                </Container>
            </Section>

            <Section background="blue">
                <Container>
                    <StyledH2Headline as="h2" size="small">
                        <FormattedMessage {...messages.benefitsHeadline} />
                    </StyledH2Headline>
                    <BenefitList companyBenefits={companyBenefits} />
                </Container>
            </Section>

            <JobSection
                headlineType="h1"
                jobs={sortedJobs[intl.locale]}
                jobAmountToShow={7}
            />
        </>
    );
};

export default Oediv;
