import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'radeberger.pageTitle',
        defaultMessage: 'radeberger.pageTitle',
    },
    metaDescription: {
        id: 'radeberger.metaDescription',
        defaultMessage: 'radeberger.metaDescription',
    },
    headline: {
        id: 'radeberger.headline',
        defaultMessage: 'radeberger.headline',
    },
    firstSectionText1: {
        id: 'radeberger.firstSection.text1',
        defaultMessage: 'radeberger.firstSection.text1',
    },
    section3HeadlineMain: {
        id: 'radeberger.section3.headlineMain',
        defaultMessage: 'radeberger.section3.headlineMain',
    },
    section3Teaser1Headline: {
        id: 'radeberger.section3.teaser1.headline',
        defaultMessage: 'radeberger.section3.teaser1.headline',
    },
    section3Teaser1Text: {
        id: 'radeberger.section3.teaser1.text',
        defaultMessage: 'radeberger.section3.teaser1.text',
    },
    section3Teaser1PopupText: {
        id: 'radeberger.section3.teaser1.popupText',
        defaultMessage: 'radeberger.section3.teaser1.popupText',
    },
    section3Teaser2Headline: {
        id: 'radeberger.section3.teaser2.headline',
        defaultMessage: 'radeberger.section3.teaser2.headline',
    },
    section3Teaser2Text: {
        id: 'radeberger.section3.teaser2.text',
        defaultMessage: 'radeberger.section3.teaser2.text',
    },
    section3Teaser2PopupText: {
        id: 'radeberger.section3.teaser2.popupText',
        defaultMessage: 'radeberger.section3.teaser2.popupText',
    },
    section3Teaser3Headline: {
        id: 'radeberger.section3.teaser3.headline',
        defaultMessage: 'radeberger.section3.teaser3.headline',
    },
    section3Teaser3Text: {
        id: 'radeberger.section3.teaser3.text',
        defaultMessage: 'radeberger.section3.teaser3.text',
    },
    section3Teaser3PopupText: {
        id: 'radeberger.section3.teaser3.popupText',
        defaultMessage: 'radeberger.section3.teaser3.popupText',
    },
});

export default messages;
