import React, {
    createContext,
    FC,
    ReactNode,
    useState,
    useContext,
} from 'react';
import { useHistory } from 'react-router';
import Popup from '../../components/Popup/Popup';

interface PopupProviderProps {
    children: ReactNode;
}

interface PopupProviderState {
    isPopupOpen: boolean;
    togglePopup: (content: ReactNode, jobId?: string) => void;
    popupContentId?: string;
}

export const PopupContext = createContext<PopupProviderState | undefined>(
    undefined
);

const PopupProvider: FC<PopupProviderProps> = ({ children }) => {
    const [popupContent, setPopupContent] = useState();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupContentId, setPopupContentId] = useState();
    const history = useHistory();

    const openPopup = (content: ReactNode, jobId?: string) => {
        setPopupContent(content);
        setIsPopupOpen(true);
        setPopupContentId(jobId);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setPopupContentId('');
    };

    const togglePopup = (content: ReactNode, jobId?: string) => {
        !isPopupOpen ? openPopup(content, jobId) : closePopup();
    };

    // close popup on browser backbutton click
    history.listen((x, action) => {
        if (isPopupOpen && action === 'POP') {
            closePopup();
        }
    });

    return (
        <PopupContext.Provider
            value={{
                isPopupOpen,
                togglePopup,
                popupContentId,
            }}
        >
            {children}

            <Popup onClose={closePopup} isOpen={isPopupOpen}>
                {popupContent}
            </Popup>
        </PopupContext.Provider>
    );
};

export const usePopup = () => {
    const context = useContext(PopupContext);

    if (!context) {
        throw new Error('usePopup must be used within a PopupProvider');
    }

    return context;
};

export default PopupProvider;
