import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import styled, { css } from 'styled-components';
import { TranslationContext } from '../../provider/TranslationProvider/TranslationProvider';
import { fromTheme, minBreakpoint } from '../../utils';

export type languageType = 'de' | 'en';

const Nav = styled('nav')`
    padding: 15px 0;
    border-bottom: 1px solid ${fromTheme('colors', 'grey500')};

    ${minBreakpoint.md`
        text-align: right;
        border-bottom: none;
    `}
`;

const LinkContainer = styled('div')<{ isActive: boolean }>`
    padding-left: 30px;
    display: inline-block;

    &:not(:last-child) {
        padding-right: 30px;
        padding-left: 0;
        border-right: 1px solid ${fromTheme('colors', 'grey500')};
    }

    ${minBreakpoint.md`
        padding: 0 4px;

        &:not(:last-child) {
            border-right: 2px solid ${fromTheme('colors', 'floatingText')};
            padding: 0 4px;
        }
    `}

    ${(props) =>
        props.isActive &&
        css`
            ${StyledNavItem} {
                color: ${fromTheme('colors', 'blue500')};
            }
        `}
`;

const StyledNavItem = styled('button')`
    color: ${fromTheme('colors', 'blue200')};
    background-color: ${fromTheme('colors', 'white')};
    text-decoration: none;
    border: 0;
    font-size: ${fromTheme('fontSize', 'base')};
    font-family: ${fromTheme('fontFamily', 'base')};
    cursor: pointer;
    transition: font-weight 0.2s;

    ${minBreakpoint.md`
        color: ${fromTheme('colors', 'floatingText')};
        font-weight: ${fromTheme('fontWeight', 'bold')};
    `}

    &:focus {
        outline: none;
    }
`;

const LanguageSwitch: React.FC = ({ ...props }) => {
    const history = useHistory();
    const intl = useIntl();
    const location = useLocation();

    const [redirectRouteKey, setRedirectRouteKey] = useState('');
    const [redirectSubPagePath, setRedirectSubPagePath] = useState('');
    const { setLocale, setLanguageSwitched } = useContext(TranslationContext);

    const languages = [
        { text: 'Deutsch', type: 'de' },
        { text: 'English', type: 'en' },
    ];

    /*
     * function to switch to another language
     */
    const switchLanguage = (language: string) => {
        setLocale(language);

        // get current route key from pathname
        const pathnameOfRoute = location.pathname.split(`/${intl.locale}/`)[1];
        const route =
            pathnameOfRoute && pathnameOfRoute !== ''
                ? pathnameOfRoute.split('/')[0]
                : '';

        const routeKey =
            route !== ''
                ? Object.keys(intl.messages).find(
                      (message) => intl.messages[message] === route
                  )
                : '';

        if (routeKey !== undefined) {
            // get sub page path, like company name
            const subPagePath = pathnameOfRoute.split('/')[1];

            // set redirect path to new language with current route key (and company path
            // will be read out by useEffect after language & messages changed
            setRedirectRouteKey(routeKey);

            if (subPagePath) {
                setRedirectSubPagePath(subPagePath);
            } else {
                setRedirectSubPagePath('');
            }
        }

        // only for jobs page: trigger filter reset
        if (routeKey === 'route.jobs') {
            setLanguageSwitched(language);
        }
    };

    useEffect(() => {
        // redirects the url after translation switch, redirects per default to home
        if (redirectRouteKey) {
            history.push(
                `/${intl.locale}/` +
                    intl.formatMessage({ id: redirectRouteKey }) +
                    '/' +
                    redirectSubPagePath
            );
        }
    }, [intl.messages, intl, history, redirectRouteKey, redirectSubPagePath]);

    return (
        <Nav>
            {languages.map((lang) => {
                return (
                    <LinkContainer
                        key={lang.type}
                        isActive={lang.type === intl.locale}
                    >
                        <StyledNavItem
                            onClick={() => switchLanguage(lang.type)}
                        >
                            {lang.text}
                        </StyledNavItem>
                    </LinkContainer>
                );
            })}
        </Nav>
    );
};

export default LanguageSwitch;
