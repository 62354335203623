import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { fromTheme, breakpoints } from '../../utils';

interface ButtonProps {
    isGhost?: boolean;
    hasFullWidth?: boolean;
    children: ReactNode;
    to?: string;
    href?: string;
    target?: string;
    rel?: string;
    ariaLabel?: string;
}

const Button = styled('button')<ButtonProps>`
    appearance: none;
    user-select: none;
    background-color: transparent;
    color: ${fromTheme('colors', 'white')};
    width: fit-content;
    border-radius: 6px;
    border: 0;
    font-size: ${fromTheme('fontSize', 'button')};
    font-family: ${fromTheme('fontFamily', 'base')};
    font-weight: ${fromTheme('fontWeight', 'bold')};
    padding: 10px 15px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 20px;
    outline: none;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 6px;
    }

    &::before {
        width: 100%;
        background-color: ${fromTheme('colors', 'blue500')};
    }

    &:after {
        width: 0;
        background-color: ${fromTheme('colors', 'blue800')};
        transition: width 0.15s ease-in-out;
    }

    &:hover::after {
        width: 100%;
    }

    ${(props) =>
        props.isGhost &&
        css`
            border-radius: 4px;
            border: 2px solid ${fromTheme('colors', 'white')};
            width: 100%;
            max-width: 345px;
            height: 45px;
        `};

    ${(props) =>
        props.hasFullWidth &&
        css`
            width: 100%;
            text-align: center;

            @media (min-width: ${breakpoints.md}px) {
                width: auto;
                text-align: left;
            }
        `}
`;

export default Button;
