export default {
    /* Colours --------------------------------------------- */
    // 100 = darkest | 500 = default | 900 = lightest

    colors: {
        floatingText: '#00051d',

        white: '#fff',
        black: '#000',

        // grey
        grey200: '#999FA5',
        grey300: '#d8d9d8',
        grey400: '#868e98',
        grey500: '#e5e8ec',
        grey600: '#a1a9b5',
        grey700: '#BEC2BE',
        grey800: '#f1f1f1',

        // blue
        blue100: '#000e20',
        blue200: '#8E9FB4',
        blue500: '#2D78D3',
        blue800: '#4E99F5',

        //red
        red500: '#e02128',

        // company colors
        radeberger: '#C9AB37',
        coppenrath: '#DB0A11',
        oetkerDigital: '#1d68c7',
    },

    /* Typography ------------------------------------------ */

    // size
    fontSize: {
        base: '16px',
        benefits: '18px',
        bigText: '22px',
        button: '16px',
        select: '16px',
        footer: '16px',
        headlineRegular: '26px',
        headlineSmall: '22px',
        headlineSmallest: '16px',
        navigation: '16px',
        popupText: '16px',
        tag: '14px',

        md: {
            base: '18px',
            bigText: '26px',
            headlineRegular: '42px',
            headlineSmall: '26px',
            headlineSmallest: '18px',
            popupText: '18px',
            tag: '16px',
        },
    },

    // weight
    fontWeight: {
        regular: '400',
        bold: '700',
        extraBold: '900',
    },

    // all about base grid
    grid: {
        outerSpace: '20px',

        md: {
            pageMaxWidth: '1440px',
            pageInnerMaxWidth: '1120px',

            columnThirdWidth: '31%',
            columnThirdSpacing: '3%',

            columnHalfWidth: '47.5%',
            columnHalfSpacing: '5%',
        },
    },

    // font-family
    fontFamily: {
        base: 'PT Sans',
    },

    // component related stuff
    popup: {
        background: '#fff',
        textPopupInnerPadding: '50px 90px 90px 50px',
    },
};
