import { createGlobalStyle } from 'styled-components';

import PTSans from '../../../assets/fonts/ptsans-regular.ttf';
import PTSansWOFF from '../../../assets/fonts/ptsans-regular.woff';
import PTSansBold from '../../../assets/fonts/ptsans-bold.ttf';
import PTSansBoldWOFF from '../../../assets/fonts/ptsans-bold.woff';
import PTSansExtraBoldEOT from '../../../assets/fonts/pt-sans-pro_extra-bold.eot';
import PTSansExtraBoldTTF from '../../../assets/fonts/pt-sans-pro_extra-bold.ttf';
import { fromTheme, breakpoints } from '../../../utils';

const GlobalStyle = createGlobalStyle`
    /* regular latin */
    @font-face {
        font-family: ${fromTheme('fontFamily', 'base')};
        font-style: normal;
        font-weight: ${fromTheme('fontWeight', 'regular')};
        font-display: swap;
        src:
            url(${PTSans}) format('truetype'),
            url(${PTSansWOFF}) format('woff')
        ;
    }

    /* bold */
    @font-face {
        font-family: ${fromTheme('fontFamily', 'base')};
        font-style: normal;
        font-weight: ${fromTheme('fontWeight', 'bold')};
        font-display: swap;
        src:
            url(${PTSansBold}) format('truetype'),
            url(${PTSansBoldWOFF}) format('woff')
        ;
    }

    /* extra bold in design */
    @font-face {
        font-family: ${fromTheme('fontFamily', 'base')};
        src:
            url(${PTSansExtraBoldTTF}) format('truetype'),
            url(${PTSansExtraBoldEOT}) format('opentype')
        ;
        font-weight: ${fromTheme('fontWeight', 'extraBold')};
        font-style: normal;
    }

    html {
        height: 100%;
    }

    *, *::before, *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    /* Remove visible outline for non-keyboard users */
    :focus:not(:focus-visible) { outline: none }

    body {
        height: 100%;
        font-family: 'PT Sans', Arial, sans-serif;
        color: ${fromTheme('colors', 'floatingText')};
        font-size: ${fromTheme('fontSize', 'base')};

        @media (min-width:${breakpoints.md}px) {
            font-size: ${fromTheme('fontSize', 'base', 'md')};
        }
    }

    #root {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    ul {
        margin-bottom: 1rem;
        margin-left: 20px;
        text-align: left;
    }

    p {
        margin-bottom: 30px;
    }

    hr {
        height: 1px;
        width: 100%;
        border: 0 solid transparent;
        background-color: ${fromTheme('colors', 'grey500')};
    }
`;

export default GlobalStyle;
