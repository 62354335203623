import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { breakpoints, fromTheme } from '../../utils';

export type HeadlineSize = 'regular' | 'small' | 'small--light' | 'smallest';
export interface HeadlineProps {
    as?: keyof JSX.IntrinsicElements;
    children: ReactNode;
    size?: HeadlineSize;
}

const StyledHeadline = styled('h2')<HeadlineProps>`
    font-family: ${fromTheme('fontFamily', 'base')};
    font-weight: ${fromTheme('fontWeight', 'extraBold')};
    word-break: break-word;

    @media (min-width: ${breakpoints.md}px) {
        word-break: initial;
    }

    ${(props) =>
        props.size === 'regular'
            ? css`
                  font-size: ${fromTheme('fontSize', 'headlineRegular')};
                  margin: 0 0 45px;

                  @media (min-width: ${breakpoints.md}px) {
                      font-size: ${fromTheme(
                          'fontSize',
                          'headlineRegular',
                          'md'
                      )};
                      margin: 0 0 40px;
                  }
              `
            : ''}

    ${(props) =>
        props.size === 'small'
            ? css`
                  font-size: ${fromTheme('fontSize', 'headlineSmall')};
                  margin: 0 0 15px;

                  @media (min-width: ${breakpoints.md}px) {
                      font-size: ${fromTheme(
                          'fontSize',
                          'headlineSmall',
                          'md'
                      )};
                      margin: 0 0 25px;
                  }
              `
            : ''}

        ${(props) =>
        props.size === 'small--light'
            ? css`
                  font-size: ${fromTheme('fontSize', 'headlineSmall')};
                  font-weight: ${fromTheme('fontWeight', 'bold')};
                  margin: 0 0 15px;

                  @media (min-width: ${breakpoints.md}px) {
                      font-size: ${fromTheme(
                          'fontSize',
                          'headlineSmall',
                          'md'
                      )};
                      margin: 0 0 25px;
                  }
              `
            : ''}

        ${(props) =>
        props.size === 'smallest'
            ? css`
                  font-size: ${fromTheme('fontSize', 'headlineSmallest')};
                  margin: 25px 0 10px;

                  @media (min-width: ${breakpoints.md}px) {
                      font-size: ${fromTheme(
                          'fontSize',
                          'headlineSmallest',
                          'md'
                      )};
                      margin-top: 30px;
                  }
              `
            : ''}
`;

const Headline: React.FC<HeadlineProps> = ({
    size = 'regular',
    ...props
}: HeadlineProps) => <StyledHeadline size={size} {...props} />;

export default Headline;
