import React from 'react';
import styled, { css } from 'styled-components';

interface BackdropProps {
    isOpen?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Backdrop = styled('div')<BackdropProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all 0.15s ease-in-out 0s;
    background: rgba(0, 0, 0, 0.5);
    z-index: ${(props) => (props.isOpen ? '1000' : '-1000')};
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    cursor: pointer;

    ${(props) =>
        props.isOpen &&
        css`
            visibility: visible;
            z-index: 999;
        `}
`;

export default Backdrop;
