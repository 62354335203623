import { defineMessages } from 'react-intl';

const messages = defineMessages({
    closeButtonTitle: {
        id: 'offCanvas.closeButton.title',
        defaultMessage: 'offCanvas.closeButton.title',
    },
});

export default messages;
