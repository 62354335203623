/**
 * Get a property from the theme.
 */
export const fromTheme =
    (prop: string, nestedProp?: string, breakpoint?: string): any =>
    (props: { theme: any }): any => {
        if (nestedProp) {
            return breakpoint
                ? props.theme[prop][breakpoint][nestedProp]
                : props.theme[prop][nestedProp];
        } else {
            return props.theme[prop];
        }
    };
