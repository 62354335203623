import React, { useState } from 'react';

import { useJobContent } from '../../provider/JobDataProvider/JobContentProvider';
import AccordionItem from '../AccordionItem/AccordionItem';
import JobListItemAccordion from '../JobListItem/Accordion/JobListItemAccordion';
import JobItemDescription from '../JobListItem/JobItemDescription';
import { JobProps } from '../../types/common';

interface JobListAccordionProps {
    jobs: JobProps[];
    amountOfJobsToShow: number;
}

const JobListAccordion: React.FC<JobListAccordionProps> = ({
    jobs,
    amountOfJobsToShow,
    ...props
}) => {
    const [openAccordionId, setOpenAccordionId] = useState('');
    const { getJobContent } = useJobContent();

    return (
        <div>
            {jobs.slice(0, amountOfJobsToShow).map((job) => {
                return (
                    <AccordionItem
                        key={`AccordionItem-${job.id}`}
                        headerContent={<JobListItemAccordion job={job} />}
                        onClick={() => {
                            getJobContent(job);
                            setOpenAccordionId(job.id);
                        }}
                        openAccordionId={openAccordionId}
                        jobId={parseInt(job.id)}
                    >
                        <JobItemDescription
                            href={job.link}
                            isSmallHeight={true}
                            jobTitle={job.title}
                        />
                    </AccordionItem>
                );
            })}
        </div>
    );
};

export default JobListAccordion;
