import { defineMessages } from 'react-intl';

const messages = defineMessages({
    footerNavItemCompliance: {
        id: 'footer.nav.item2',
        defaultMessage: 'footer.nav.item2',
    },
    footerNavItemComplianceHref: {
        id: 'footer.nav.item2.href',
        defaultMessage: 'footer.nav.item2.href',
    },
    footerNavItemDataProtection: {
        id: 'footer.nav.item3',
        defaultMessage: 'footer.nav.item3',
    },
    footerNavItemDataProtectionHref: {
        id: 'footer.nav.item3.href',
        defaultMessage: 'footer.nav.item3.href',
    },
    footerNavItemLegal: {
        id: 'footer.nav.item6',
        defaultMessage: 'footer.nav.item6',
    },
    footerNavItemLegalHref: {
        id: 'footer.nav.item6.href',
        defaultMessage: 'footer.nav.item6.href',
    },
    footerNavItemImprint: {
        id: 'footer.nav.item5',
        defaultMessage: 'footer.nav.item5',
    },
    footerNavItemImprintHref: {
        id: 'footer.nav.item5.href',
        defaultMessage: 'footer.nav.item5.href',
    },
    footerNavItemCookieSettings: {
        id: 'footer.nav.item4',
        defaultMessage: 'footer.nav.item4',
    },
    footerNavLabel: {
        id: 'footer.nav.label',
        defaultMessage: 'footer.nav.label',
    },
});

export default messages;
