import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'jobs.pageTitle',
        defaultMessage: 'jobs.pageTitle',
    },
    metaDescription: {
        id: 'jobs.metaDescription',
        defaultMessage: 'jobs.metaDescription',
    },
});

export default messages;
