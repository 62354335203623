import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'oediv.pageTitle',
        defaultMessage: 'oediv.pageTitle',
    },
    metaDescription: {
        id: 'oediv.metaDescription',
        defaultMessage: 'oediv.metaDescription',
    },
    headline: {
        id: 'oediv.headline',
        defaultMessage: 'oediv.headline',
    },
    section1Text1: {
        id: 'oediv.section1.text1',
        defaultMessage: 'oediv.section1.text1',
    },
    section1Text2: {
        id: 'oediv.section1.text2',
        defaultMessage: 'oediv.section1.text2',
    },
    section1Text3: {
        id: 'oediv.section1.text3',
        defaultMessage: 'oediv.section1.text3',
    },
    section1Text4: {
        id: 'oediv.section1.text4',
        defaultMessage: 'oediv.section1.text4',
    },
    section1Text5: {
        id: 'oediv.section1.text5',
        defaultMessage: 'oediv.section1.text5',
    },
    section1Text6: {
        id: 'oediv.section1.text6',
        defaultMessage: 'oediv.section1.text6',
    },
    section1Text7: {
        id: 'oediv.section1.text7',
        defaultMessage: 'oediv.section1.text7',
    },
    contactHeadline: {
        id: 'oediv.contactSection.headline',
        defaultMessage: 'oediv.contactSection.headline',
    },
    personCard1Headline: {
        id: 'oediv.personCard1.headline',
        defaultMessage: 'oediv.personCard1.headline',
    },
    personCard1Text: {
        id: 'oediv.personCard1.text',
        defaultMessage: 'oediv.personCard1.text',
    },
    personCard2Headline: {
        id: 'oediv.personCard2.headline',
        defaultMessage: 'oediv.personCard2.headline',
    },
    personCard2Text: {
        id: 'oediv.personCard2.text',
        defaultMessage: 'oediv.personCard2.text',
    },
});

export default messages;
