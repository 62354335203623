import React, { ReactChild } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { breakpoints, fromTheme } from '../../utils';
import Backdrop from '../Backdrop/Backdrop';

interface PopupProps {
    isOpen?: boolean;
    root?: HTMLElement;
    children: ReactChild;
    onClose: () => void;
}

const PopupWrapper = styled('div')<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all 0.15s ease-in-out 0s;
    z-index: ${(props) => (props.isOpen ? '1000' : '-1000')};
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    cursor: pointer;

    ${(props) =>
        props.isOpen &&
        css`
            visibility: visible;
            z-index: 999;
        `}
`;

const PopupContentContainer = styled('div')`
    position: relative;
    padding: 0 20px 20px;
    background: ${fromTheme('colors', 'white')};
    max-width: 100%;
    max-height: 90%;
    height: 100vh;
    cursor: default;
    pointer-events: none;
    z-index: 999;

    @media (min-width: ${breakpoints.lg}px) {
        width: calc(
            ${fromTheme('grid', 'pageInnerMaxWidth', 'md')} +
                ${fromTheme('grid', 'outerSpace')}
        );
        height: auto;
        top: auto;
    }
`;

const CloseButton = styled('button')`
    position: absolute;
    top: 5px;
    right: 2px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border: 0;
    z-index: 1;
    color: ${fromTheme('colors', 'grey700')};
    background-color: ${fromTheme('colors', 'white')};
    transition: color 0.2s;
    outline: none;
    background-color: transparent;

    &:hover {
        color: ${fromTheme('colors', 'black')};
    }

    @media (min-width: ${breakpoints.xl}px) {
        top: 30px;
        right: 20px;
    }
`;

const Popup: React.FC<PopupProps> = ({
    isOpen = false,
    onClose,
    children,
    ...props
}) => (
    <PopupWrapper
        isOpen={isOpen}
        {...props}
        data-testid="popup"
        data-is-visible={isOpen}
    >
        <Backdrop isOpen={isOpen} onClick={onClose} />
        <PopupContentContainer>
            <CloseButton
                onClick={onClose}
                data-testid="close-button"
                aria-label="close-button"
            >
                <CloseIcon />
            </CloseButton>
            {children}
        </PopupContentContainer>
    </PopupWrapper>
);

export default Popup;
