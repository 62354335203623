import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { fromTheme, minBreakpoint } from '../../../../src/utils';
import messages from './Hamburger.messages';

interface HamburgerProps {
    onClick: () => void;
}

const HamburgerIcon = styled('button')`
    left: 20px;
    top: 20px;
    padding: 9px 0;
    background-clip: content-box;
    box-sizing: content-box;
    cursor: pointer;

    &,
    &::before,
    &::after {
        content: '';
        width: 24px;
        height: 2px;
        display: block;
        background-color: ${fromTheme('colors', 'floatingText')};
        border: none;
        position: absolute;
    }

    &::before {
        top: 2px;
    }

    &::after {
        bottom: 2px;
    }

    ${minBreakpoint.md`
        display: none;
    `}
`;

const Hamburger: React.FC<HamburgerProps> = ({ onClick, ...props }) => {
    const intl = useIntl();

    return (
        <HamburgerIcon
            title={intl.formatMessage(messages.hamburgerTitle)}
            onClick={onClick}
            aria-label={intl.formatMessage(messages.hamburgerTitle)}
        />
    );
};

export default Hamburger;
