import React, { FormEvent } from 'react';
import styled from 'styled-components';
import { breakpoints, fromTheme } from '../../utils';

interface SelectProps {
    options: string[];
    label: string;
    forAttribute: string;
    handleChange?: (e: FormEvent<HTMLSelectElement>) => void;
    value: any;
}

const StyledSelect = styled('select')`
    border: 0;
    height: 45px;
    background-color: ${fromTheme('colors', 'white')};
    border-radius: 2px;
    width: 100%;
    vertical-align: middle;
    font-weight: ${fromTheme('fontWeight', 'bold')};
    font-size: ${fromTheme('fontSize', 'select')};
    font-family: ${fromTheme('fontFamily', 'base')};
    max-width: 345px;
    padding-left: 25px;
    padding-right: 25px;
    -webkit-appearance: none;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOHB4IiBoZWlnaHQ9IjEycHgiIHZpZXdCb3g9IjAgMCA4IDEyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIGlkPSJXZWJzaXRlIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iMDItU3RlbGxlbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE3MS4wMDAwMDAsIC05OTguMDAwMDAwKSIgZmlsbD0iIzAwMEUyMCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGgKICAgICAgICAgICAgICAgIGQ9Ik0xNzAuMjAzNzY4LDEwMDAgTDE3OS43OTYyMzIsMTAwMCBDMTgwLjM0ODUxNywxMDAwIDE4MC43OTYyMzIsMTAwMC40NDc3MiAxODAuNzk2MjMyLDEwMDEgQzE4MC43OTYyMzIsMTAwMS4yNDIyMiAxODAuNzA4MzE0LDEwMDEuNDc2MjEgMTgwLjU0ODgwOCwxMDAxLjY1ODUgTDE3NS43NTI1NzcsMTAwNy4xMzk5MSBDMTc1LjM4ODg5NSwxMDA3LjU1NTU1IDE3NC43NTcxMzIsMTAwNy41OTc2NyAxNzQuMzQxNDk1LDEwMDcuMjMzOTggQzE3NC4zMDgwODUsMTAwNy4yMDQ3NSAxNzQuMjc2NjU4LDEwMDcuMTczMzIgMTc0LjI0NzQyMywxMDA3LjEzOTkxIEwxNjkuNDUxMTkyLDEwMDEuNjU4NSBDMTY5LjA4NzUwOSwxMDAxLjI0Mjg3IDE2OS4xMjk2MjcsMTAwMC42MTExMSAxNjkuNTQ1MjY0LDEwMDAuMjQ3NDIgQzE2OS43Mjc1NTUsMTAwMC4wODc5MiAxNjkuOTYxNTQ1LDEwMDAgMTcwLjIwMzc2OCwxMDAwIFoiCiAgICAgICAgICAgICAgICBpZD0iUGF0aC0yLUNvcHktNSIKICAgICAgICAgICAgICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3NS4wMDAwMDAsIDEwMDQuMDAwMDAwKSByb3RhdGUoMCkgdHJhbnNsYXRlKC0xNzUuMDAwMDAwLCAtMTAwNC4wMDAwMDApICI+CiAgICAgICAgICAgIDwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=')
        right 10px center / 8px 10px no-repeat white;
    margin: 0 0 15px;
    outline: none;
    cursor: pointer;

    /* Borrowed from Bootstrap's _custom-froms.scss to make select's look consistent on all platform and browsers */
    appearance: none;
    background-color: ${fromTheme('colors', 'white')};
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 8px 10px; // Hides the default caret in IE11
    &::-ms-expand {
        opacity: 0;
    }

    @media (min-width: ${breakpoints.sm}px) {
        margin: 0 13px 15px;
    }

    @media (min-width: ${breakpoints.md}px) {
        transform: translateX(0);
        margin-left: 0;
        margin-bottom: 0;
    }

    @media (min-width: ${fromTheme('grid', 'pageInnerMaxWidth', 'md')}) {
        margin: 0 auto;
    }
`;

const StyledLabel = styled('label')<{ htmlFor: string }>`
    display: none;
`;

const Select: React.FC<SelectProps> = ({
    options,
    label,
    forAttribute,
    handleChange,
    value,
    ...props
}) => {
    return (
        <React.Fragment>
            <StyledLabel htmlFor={forAttribute}>{label}</StyledLabel>
            <StyledSelect
                id={forAttribute}
                onChange={handleChange}
                value={value}
                {...props}
            >
                {options.map((option: string) => (
                    <option key={option}>{option}</option>
                ))}
            </StyledSelect>
        </React.Fragment>
    );
};

export default Select;
