import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import stagePathMobile from '../../assets/images/cookie-settings/stage-1x.jpg';
import stagePathTablet from '../../assets/images/cookie-settings/stage-2x.jpg';
import Button from '../../components/Button/Button';
import { FlexContainer } from '../../components/container/FlexContainer/FlexContainer';
import { Container } from '../../components/container/PageContainer/PageContainer';
import Section from '../../components/container/Section/Section';
import Headline from '../../components/Headline/Headline';
import Stage from '../../components/Stage/Stage';
import PageHead from '../../components/base/PageHead/PageHead';
import RadioButton from '../../components/RadioButton/RadioButton';
import { useAnalytics } from '../../provider/AnalyticsProvider/AnalyticsProvider';
import messages from './CookieSettings.messages';
import { getActualCookie } from '../../utils';

const StyledButton = styled(Button)`
    margin: 0 auto;
    display: block;
`;

const CookieSettings = () => {
    const intl = useIntl();
    const history = useHistory();
    const { saveSettings } = useAnalytics();

    const actualCookieValue = getActualCookie();
    const [cookieValue, setCookieValue] = useState(actualCookieValue);

    const cookieOptionData = [
        {
            id: 'cookie-1',
            value: 1,
            headline: <FormattedMessage {...messages.section1Headline} />,
            message: <FormattedMessage {...messages.section1Text} />,
        },
        {
            id: 'cookie-2',
            value: 2,
            headline: <FormattedMessage {...messages.section2Headline} />,
            message: <FormattedMessage {...messages.section2Text} />,
        },
        {
            id: 'cookie-3',
            value: 3,
            headline: <FormattedMessage {...messages.section3Headline} />,
            message: <FormattedMessage {...messages.section3Text} />,
        },
    ];

    return (
        <>
            <PageHead
                pageTitle={intl.formatMessage(messages.pageTitle)}
                metaDescription={intl.formatMessage(messages.metaDescription)}
            />
            <Stage
                imgPathMobile={stagePathMobile}
                imgPathTablet={stagePathTablet}
                alt={intl.formatMessage(messages.headline)}
            />
            <Section>
                <Container>
                    <Headline as="h1">
                        <FormattedMessage {...messages.headline} />
                    </Headline>

                    <FlexContainer columnType="third">
                        {cookieOptionData.map((option) => {
                            return (
                                <RadioButton
                                    key={option.id}
                                    id={option.id}
                                    headline={option.headline}
                                    message={option.message}
                                    name="cookie-option"
                                    onChange={(e) =>
                                        setCookieValue(parseInt(e.target.value))
                                    }
                                    value={option.value}
                                    checked={option.value === cookieValue}
                                />
                            );
                        })}
                    </FlexContainer>
                </Container>
                <StyledButton
                    onClick={() => {
                        saveSettings(cookieValue);
                        history.goBack();
                    }}
                >
                    <FormattedMessage {...messages.button} />
                </StyledButton>
            </Section>
        </>
    );
};

export default CookieSettings;
