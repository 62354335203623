import { defineMessages } from 'react-intl';

const messages = defineMessages({
    hamburgerTitle: {
        id: 'header.hamburgerIcon.title',
        defaultMessage: 'header.hamburgerIcon.title',
    },
});

export default messages;
