import styled, { css } from 'styled-components';
import { fromTheme, breakpoints } from '../../../utils';

interface SectionProps {
    background?: string;
}

const Section = styled('section')<SectionProps>`
    padding: 25px 0;

    @media (min-width: ${breakpoints.md}px) {
        padding: 60px 0;
    }

    ${(props) =>
        props.background === 'blue' &&
        css`
            background-color: ${fromTheme('colors', 'blue500')};
            color: ${fromTheme('colors', 'white')};
        `}

    ${(props) =>
        props.background === 'grey' &&
        css`
            background-color: ${fromTheme('colors', 'grey500')};
        `}
`;

export default Section;
