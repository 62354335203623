const getJobs = async (
    apiUrl: string,
    locale: string,
    jobOverview: boolean = false
) => {
    let response;

    try {
        // params for all jobs from categories: "digital projects (id 18)", "digitalisation"(id 21) oder "information technology" (id 4)
        // categories vary per company, for Martin Braun is category IT = 1 <> for Bankhaus Lampe is category IT = 7
        // maxCacheAge=3600 - takes cached data from the last hour
        // fields=small - gets as less keys as possible, as much as necessary
        response = await fetch(
            `https://${apiUrl}/jobPublication/list.json?${
                jobOverview ? 'categoryId=4&categoryId=21&categoryId=18&' : ''
            }maxCacheAge=3600${
                jobOverview ? '&fields=small' : ''
            }&lang=${locale}`
        );

        // error handling
        if (!response.ok) {
            throw Error(response.statusText);
        }

        const plainJobs = await response.json();

        // filter OEDIV jobs out
        const jobs = plainJobs.filter(
            (job: any) => job.jobOpening.company.internalName !== 'OEDIV'
        );

        return jobs;
    } catch (e) {
        return ['error'];
    }
};

export default getJobs;
