import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { breakpoints, isFeatureSupport } from '../../../src/utils';
import LazyLoad from '../container/LazyLoad/LazyLoad';

interface PictureProps {
    alt: string;
    imgPathDesktop?: string;
    imgPathTablet?: string;
    imgPathMobile?: string;
    lazyload?: boolean;
    fallBackImgCss?: FlattenSimpleInterpolation;
}

const Img = styled('img')<{ customStyles?: FlattenSimpleInterpolation }>`
    min-height: auto;
    width: 100%;
    max-width: 100%;
    ${(props) => props.customStyles}
`;

const StyledPicture = styled('picture')`
    max-width: 100%;
`;

const StyledImg = styled('img')`
    max-width: 100%;
    display: block;
`;

function getFallBackImgPath(
    imgPathDesktop: string,
    imgPathTablet: string,
    imgPathMobile: string
) {
    // mobile breakpoint: try to get mobile image, if there's no mobile image try another img
    let fallbackImgPath = '';
    if (window.innerWidth < breakpoints.md) {
        fallbackImgPath = imgPathMobile || imgPathTablet || imgPathDesktop;
    }

    // desktop breakpoint: try to get desktop image, if there's no desktop image try another img
    if (window.innerWidth > breakpoints.lg) {
        fallbackImgPath = imgPathDesktop || imgPathTablet || imgPathMobile;
    }

    // tablet breakpoint: try to get tablet image, if there's no tablet image try another img
    if (
        window.innerWidth <= breakpoints.lg &&
        window.innerWidth >= breakpoints.md
    ) {
        fallbackImgPath = imgPathTablet || imgPathDesktop || imgPathMobile;
    }

    return fallbackImgPath;
}

const Picture: React.FC<PictureProps> = ({
    alt,
    imgPathDesktop,
    imgPathTablet,
    imgPathMobile,
    lazyload = true,
    fallBackImgCss,
    ...props
}: PictureProps) => {
    const Image = isFeatureSupport('pictureTag') ? (
        <StyledPicture {...props}>
            {imgPathDesktop && (
                <source
                    media={`(min-width: ${breakpoints.lg}px)`}
                    srcSet={imgPathDesktop}
                />
            )}
            {imgPathTablet && (
                <source
                    media={`(min-width: ${breakpoints.md}px)`}
                    srcSet={imgPathTablet}
                />
            )}
            <StyledImg
                src={imgPathMobile ? imgPathMobile : imgPathDesktop}
                alt={alt}
            />
        </StyledPicture>
    ) : (
        <Img
            customStyles={fallBackImgCss}
            src={getFallBackImgPath(
                imgPathDesktop ? imgPathDesktop : '',
                imgPathTablet ? imgPathTablet : '',
                imgPathMobile ? imgPathMobile : ''
            )}
            alt={alt}
        />
    );

    return lazyload ? <LazyLoad {...props} component={Image} /> : Image;
};

export default Picture;
