import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import messages from './NavCareer.messages';
import routes from '../../../routes.messages';
import { fromTheme, minBreakpoint } from '../../../utils';
import { useLocation } from 'react-router';

interface NavCareerProps {
    identifier: string;
    onClick?: () => void;
}

const StyledNav = styled('nav')`
    word-break: break-word;
`;

const StyledLink = styled(Link)`
    color: ${fromTheme('colors', 'floatingText')};
    font-weight: ${fromTheme('fontWeight', 'bold')};
    font-size: ${fromTheme('fontSize', 'base')};
    text-decoration: none;

    &:hover {
        color: ${fromTheme('colors', 'blue500')};
    }

    ${minBreakpoint.md`
        &:first-child {
            border-top: none;
        }
    `}
`;

const LinkWrapper = styled('div')<{ isActive: boolean }>`
    border-bottom: 1px solid ${fromTheme('colors', 'grey500')};
    padding: 15px 0;
    display: block;
    white-space: nowrap;

    &:first-child {
        border-top: 1px solid ${fromTheme('colors', 'grey500')};
    }

    ${minBreakpoint.md`
    display: inline-block;
    padding: 0;
    border-bottom: none;
    margin-right: 40px;
`}

    ${(props) =>
        props.isActive &&
        css`
            a {
                color: ${fromTheme('colors', 'blue500')};
            }
        `}
`;

const NavCareer: React.FC<NavCareerProps> = ({
    identifier,
    onClick,
    ...props
}) => {
    const intl = useIntl();
    const { pathname } = useLocation();

    const navItems = [
        {
            text: messages.navCareerHome,
            href: '',
        },
        {
            text: messages.navCareerJobs,
            href: intl.formatMessage(routes.jobs),
        },
        {
            text: messages.navCareerGroupCompanies,
            href: intl.formatMessage(routes.companyOverview),
        },
    ];

    // detect the last index of an active nav item in navItems
    // reversing because first nav item home.href "/" would match every time
    const navItemsCopy = navItems.slice();
    const lastNavItemIndex = navItemsCopy
        .reverse()
        .findIndex((item) => pathname.includes(item.href));

    return (
        <StyledNav
            {...props}
            title={intl.formatMessage(messages.navCareerTitle)}
            aria-label={intl.formatMessage(messages.navCareerTitle)}
        >
            {navItems.map((item) => {
                const isActive =
                    item.href === navItemsCopy[lastNavItemIndex].href;

                return (
                    <LinkWrapper
                        isActive={isActive}
                        key={
                            'navCareer' +
                            identifier +
                            intl.formatMessage(item.text)
                        }
                    >
                        <StyledLink
                            to={'/' + intl.locale + '/' + item.href}
                            onClick={onClick}
                        >
                            {intl.formatMessage(item.text)}
                        </StyledLink>
                    </LinkWrapper>
                );
            })}
        </StyledNav>
    );
};

export default NavCareer;
