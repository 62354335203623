export type Breakpoint = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type Breakpoints = Record<Breakpoint, number>;

export const breakpoints: Breakpoints = {
    xxs: 0,
    xs: 350,
    sm: 600,
    md: 768,
    lg: 992,
    xl: 1200,
};
