import { defineMessages } from 'react-intl';

const messages = defineMessages({
    headlineOne: {
        id: 'jobs.headline.one',
        defaultMessage: 'jobs.headline.one',
    },
    headlineMany: {
        id: 'jobs.headline.many',
        defaultMessage: 'jobs.headline.many',
    },
    button: {
        id: 'jobs.button',
        defaultMessage: 'jobs.button',
    },
    jobsLoading: {
        id: 'jobSection.loading',
        defaultMessage: 'jobSection.loading',
    },
    jobsError: {
        id: 'jobSection.jobsError',
        defaultMessage: 'jobSection.jobsError',
    },
});

export default messages;
