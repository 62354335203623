import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface LazyLoadProps {
    threshold?: number | number[];
    rootMargin?: string;
    root?: HTMLElement | null;
    component: JSX.Element;
    placeholderHeight?: string;
    placeholderWidth?: string;
}

interface Placeholder {
    placeholderHeight: string;
    placeholderWidth: string;
}

const animation = keyframes`
    0%{background-position: 0% 50%}
    50%{background-position:100% 50%}
    100%{background-position: 100% 50%}
`;
const DefaultPlaceholder = styled.div<Placeholder>`
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(
        -45deg,
        #a5a5a5,
        #ffffff,
        #a5a5a5,
        #ffffff,
        #a5a5a5
    );
    background-size: 400% 400%;
    animation: ${animation} 2s ease infinite;
    height: ${(props) => props.placeholderHeight};
    width: ${(props) => props.placeholderWidth};
`;

const LazyLoad: React.FC<LazyLoadProps> = ({
    component,
    placeholderHeight = '100%',
    placeholderWidth = '100%',
    root = null,
    rootMargin = '0px',
    threshold = 0.25,
    ...props
}: LazyLoadProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let observer: IntersectionObserver | null = null;
        if (!('IntersectionObserver' in window)) {
            setIsVisible(true);
        } else {
            observer = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setIsVisible(true);
                            if (observer) {
                                observer.disconnect();
                            }
                        }
                    });
                },
                {
                    root: root,
                    rootMargin: rootMargin,
                    threshold: threshold,
                }
            );

            if (container.current) {
                observer.observe(container.current);
            }
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [component, root, rootMargin, threshold]);

    return isVisible ? (
        component
    ) : (
        <DefaultPlaceholder
            ref={container}
            placeholderHeight={placeholderHeight}
            placeholderWidth={placeholderWidth}
        />
    );
};

export default LazyLoad;
