import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoints, fromTheme } from '../../utils';

interface CompanyCardProps {
    imgPath: string;
    img2xPath?: string;
    imgAlt: string;
    text: string;
    to: string;
}

const StyledCompanyCard = styled(Link)`
    margin-bottom: 20px;
    display: block;
    height: 95%;
    text-decoration: none;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    background-color: ${fromTheme('colors', 'grey500')};
`;

const ImgContainer = styled('div')`
    height: 155px;
    border: 1px solid ${fromTheme('colors', 'grey300')};
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${fromTheme('colors', 'white')};
`;

const StyledImg = styled('img')`
    max-width: 85%;
`;

const Text = styled('span')`
    display: block;
    font-size: ${fromTheme('fontSize', 'bigText')};
    padding: 25px 25px 55px 25px;
    color: ${fromTheme('colors', 'floatingText')};
    word-break: break-word;

    @media (min-width: ${breakpoints.md}px) {
        font-size: ${fromTheme('fontSize', 'bigText', 'md')};
        padding: 30px 30px 55px 30px;
    }
`;

const CompanyCard: React.FC<CompanyCardProps> = ({
    imgPath,
    img2xPath,
    imgAlt,
    text,
    to,
    ...props
}: CompanyCardProps) => (
    <StyledCompanyCard to={to}>
        <ImgContainer>
            <StyledImg
                src={imgPath}
                srcSet={img2xPath ? `${imgPath} 1x, ${img2xPath} 2x` : ''}
                alt={imgAlt}
            />
        </ImgContainer>
        <Text>{text}</Text>
    </StyledCompanyCard>
);

export default CompanyCard;
