import React, {
    createContext,
    FC,
    ReactNode,
    useContext,
    useState,
} from 'react';

import { JobResponse, RequestStatus } from '../../types/common';
import getJobs from './getJobs';
import { useIntl } from 'react-intl';

interface JobContentProviderProps {
    children: ReactNode;
}

interface JobContentProviderState {
    getJobContent: (job: JobResponse) => void;
    contentRequestStatus?: RequestStatus;
    // content from specific company is different depending on API
    jobContent: any;
}

interface CompanyJobContent {
    intro: string;
    position: string;
    profile: string;
    tasks: string;
    weOffer: string;
}

interface JobId {
    id: number;
}

export const JobContentContext = createContext<
    JobContentProviderState | undefined
>(undefined);

const JobContentProvider: FC<JobContentProviderProps> = ({ children }) => {
    const [contentRequestStatus, setContentRequestStatus] =
        useState<RequestStatus>('loading');
    const [jobContent, setJobContent] = useState<any>();
    const intl = useIntl();

    const setNoContent = () => {
        setContentRequestStatus('error');
        console.info('No content for this job available');
        setJobContent({});
    };

    const findSpecificJob = (jobList: any, jobLink: string) => {
        // get job id from job.jobPublicationURL - digits in a row, with a "/" infront
        const regExJobId = '/[0-9]{2,10}';
        const matchedJob = jobLink.match(regExJobId);
        const jobId = matchedJob ? matchedJob[0].split('/')[1] : '';

        if (matchedJob === null) {
            setNoContent();
            return;
        }

        // find related job from companyData
        const companyJob = jobList.find(
            // id is sometimes number and sometimes a string
            ({ id }: JobId) => id.toString() === jobId.toString()
        );

        // (text:string) => boolean;
        const containsDummyText = (text: string) => {
            const regex = /\b(?:ipsum dolor sit amet)\b/gi;
            const isDummyText = regex.test(text) || text === '<p></p>';

            return isDummyText;
        };

        const removeCustomFontStyles = (text: string) => {
            const escapedText = text
                .replace('&quot;', '###')
                .replace('###"', ';"')
                .replace(/size=".*?"/gi, '')
                .replace(/face=".*?"/gi, '')
                .replace(/font-size:.*?pt/gi, '');
            const customFontString = /font-family:.*?;/gi;
            const filteredText = escapedText.replace(customFontString, '');

            return filteredText.replace('###', '&quot;');
        };

        if (companyJob && companyJob.renderedContent) {
            const dvinciContent = companyJob.renderedContent;
            const intro =
                dvinciContent.introduction.body &&
                !containsDummyText(dvinciContent.introduction.body)
                    ? dvinciContent.introduction.body
                    : '';
            const position =
                dvinciContent.position.body &&
                !containsDummyText(dvinciContent.position.body)
                    ? dvinciContent.position.body
                    : '';
            const profile =
                dvinciContent.profile.body &&
                !containsDummyText(dvinciContent.profile.body)
                    ? dvinciContent.profile.body
                    : '';
            const tasks =
                dvinciContent.tasks.body &&
                !containsDummyText(dvinciContent.tasks.body)
                    ? dvinciContent.tasks.body
                    : '';
            const weOffer =
                dvinciContent.weOffer.body &&
                !containsDummyText(dvinciContent.weOffer.body)
                    ? dvinciContent.weOffer.body
                    : '';

            const companyJobContent: CompanyJobContent = {
                intro: removeCustomFontStyles(intro),
                position: removeCustomFontStyles(position),
                profile: removeCustomFontStyles(profile),
                tasks: removeCustomFontStyles(tasks),
                weOffer: removeCustomFontStyles(weOffer),
            };

            setJobContent(companyJobContent);
            setContentRequestStatus('loaded');
        } else {
            setNoContent();
            return;
        }
    };

    /**
     * get job content from the specific d.vinci company API, not oetker-gruppe API
     * get id from jobPublicationURL and request specific company dvinci api
     * and set renderedContent key as  jobContent state
     **/
    const getJobContent = (job: JobResponse) => {
        setContentRequestStatus('loading');

        // variations of links to job publications:
        // from coppenrath und wiese: https://oetker-gruppe.dvinci.de/de/jobs/15990/praktikant-mwd-des-gap-year-programms-mit-schwerpunkt-new-businessentrepreneurship
        // https://radeberger-gruppe.dvinci.de/jobs/10468
        // https://bankhaus-lampe.dvinci-easy.com/jobs/30136
        // https://henkell-freixenet.dvinci-hr.com/jobs/20216
        // https://career012.successfactors.eu/sfcareer/jobreqcareer?company=C0000030898P&rcm_site_locale=de_DE&jobId=18294

        let apiUrl = '';

        // check if publication URL is '[COMPANY].dvinci.de' or '[COMPANY].dvinci-easy.com' or '[COMPANY].dvinci-hr.com' or from SAP system or oetker-gruppe.dvinci.de
        // getContent from allJobs
        if (job.link.includes('dvinci.de')) {
            apiUrl = job.link.split('/jobs')[0].split('https://')[1];
        } else if (
            job.link.includes('.dvinci-easy.com') ||
            job.link.includes('.dvinci-hr.com')
        ) {
            apiUrl = job.link.split('/jobs')[0].split('https://')[1];
        } else if (job.link.includes('successfactors.eu')) {
            // if the job was important from the SAP SuccessFactors then it doesn't have any content
            // therefor we won't display any content
            setNoContent();
            return;
        } else {
            // other systems also have no API with content data at the moment
            setNoContent();
            return;
        }

        // request specific company dvinci api and find job content
        getJobs(apiUrl, intl.locale)
            .then((allCompanyJobs) => {
                // error handling -  the API doesn't provide any helpful information in error cases
                if (
                    allCompanyJobs === undefined ||
                    allCompanyJobs[0] === 'error'
                ) {
                    setNoContent();
                    return;
                }

                if (job.link === undefined) {
                    return;
                }

                findSpecificJob(allCompanyJobs, job.link);
            })
            .catch((error) => {
                console.info(error);
            });
    };

    return (
        <JobContentContext.Provider
            value={{
                getJobContent,
                contentRequestStatus,
                jobContent,
            }}
        >
            {children}
        </JobContentContext.Provider>
    );
};

export const useJobContent = () => {
    const context = useContext(JobContentContext);

    if (!context) {
        throw new Error(
            'useJobContent must be used within a JobContentProvider'
        );
    }

    return context;
};

export default JobContentProvider;
