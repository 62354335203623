import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { fromTheme, minBreakpoint } from '../../../utils';
import messages from './NavOetkerGroup.messages';

interface NavOetkerGroupProps {
    identifier: string;
}

const StyledNav = styled('nav')`
    max-width: 650px;
    word-break: break-word;
    margin-top: 15px;
    border-top: 1px solid ${fromTheme('colors', 'white')};

    ${minBreakpoint.md`
        padding: 20px 0;
        margin-top: 0;
        border-top: none;
    `};
`;

const StyledLink = styled('a')<{ isActive: boolean }>`
    text-decoration: none;
    font-weight: ${fromTheme('fontWeight', 'regular')};
    font-size: ${fromTheme('fontSize', 'base')};
    white-space: nowrap;
    padding: 8px 0;
    border-bottom: 1px solid ${fromTheme('colors', 'white')};
    display: block;
    color: ${fromTheme('colors', 'blue200')};

    &:hover {
        color: ${fromTheme('colors', 'blue500')};
    }

    &:not(:last-child) {
        margin-right: 40px;
    }

    ${minBreakpoint.md`
        padding:0;
        border-bottom: none;
        display: inline-block;
        color: ${fromTheme('colors', 'grey700')};
        font-weight: ${fromTheme('fontWeight', 'bold')};
    `}

    ${(props) =>
        props.isActive &&
        css`
            ${minBreakpoint.md`
                color: ${fromTheme('colors', 'blue500')};
            `}
        `}
`;

const NavOetkerGroup: React.FC<NavOetkerGroupProps> = ({
    identifier,
    ...props
}) => {
    const intl = useIntl();
    const navItems = [
        {
            text: messages.navOetkerGroupHome,
            href: messages.navOetkerGroupHomeURL,
        },
        {
            text: messages.navOetkerGroupPortrait,
            href: messages.navOetkerGroupPortraitURL,
        },
        {
            text: messages.navOetkerGroupBusiness,
            href: messages.navOetkerGroupBusinessURL,
        },
        {
            text: messages.navOetkerGroupPress,
            href: messages.navOetkerGroupPressURL,
        },
        {
            text: messages.navOetkerGroupCareer,
            href: messages.navOetkerGroupCareerURL,
        },
    ];

    return (
        <StyledNav
            {...props}
            title={intl.formatMessage(messages.navOetkerGroupTitle)}
            aria-label={intl.formatMessage(messages.navOetkerGroupTitle)}
        >
            {navItems.map((item) => {
                const isActive = item.href === messages.navOetkerGroupCareerURL;

                return (
                    <StyledLink
                        href={intl.formatMessage(item.href)}
                        target="_blank"
                        rel="noopener noreferrer"
                        isActive={isActive}
                        key={
                            'navOetkerGroup' +
                            identifier +
                            intl.formatMessage(item.href)
                        }
                    >
                        {intl.formatMessage(item.text)}
                    </StyledLink>
                );
            })}
        </StyledNav>
    );
};

export default NavOetkerGroup;
