import { defineMessages } from 'react-intl';

const messages = defineMessages({
    navOetkerGroupHome: {
        id: 'navOetkerGroup.home.link.text',
        defaultMessage: 'navOetkerGroup.home.link.text',
    },
    navOetkerGroupHomeURL: {
        id: 'navOetkerGroup.home.link.href',
        defaultMessage: 'navOetkerGroup.home.link.href',
    },
    navOetkerGroupPortrait: {
        id: 'navOetkerGroup.portrait.link.text',
        defaultMessage: 'navOetkerGroup.portrait.link.text',
    },
    navOetkerGroupPortraitURL: {
        id: 'navOetkerGroup.portrait.link.href',
        defaultMessage: 'navOetkerGroup.portrait.link.href',
    },
    navOetkerGroupBusiness: {
        id: 'navOetkerGroup.business.link.text',
        defaultMessage: 'navOetkerGroup.business.link.text',
    },
    navOetkerGroupBusinessURL: {
        id: 'navOetkerGroup.business.link.href',
        defaultMessage: 'navOetkerGroup.business.link.href',
    },
    navOetkerGroupPress: {
        id: 'navOetkerGroup.press.link.text',
        defaultMessage: 'navOetkerGroup.press.link.text',
    },
    navOetkerGroupPressURL: {
        id: 'navOetkerGroup.press.link.href',
        defaultMessage: 'navOetkerGroup.press.link.href',
    },
    navOetkerGroupCareer: {
        id: 'navOetkerGroup.career.link.text',
        defaultMessage: 'navOetkerGroup.career.link.text',
    },
    navOetkerGroupCareerURL: {
        id: 'navOetkerGroup.career.link.href',
        defaultMessage: 'navOetkerGroup.career.link.href',
    },
    navOetkerGroupTitle: {
        id: 'navOetkerGroup.title',
        defaultMessage: 'navOetkerGroup.title',
    },
});

export default messages;
