import React from 'react';
import styled from 'styled-components';
import { fromTheme, minBreakpoint } from '../../../utils';
import { Container } from '../../container/PageContainer/PageContainer';
import NavOetkerGroup from '../NavOetkerGroup/NavOetkerGroup';
import NavCareer from '../NavCareer/NavCareer';
import LanguageSwitch from '../../LanguageSwitch/LanguageSwitch';

const StyledNavMainDesktop = styled('div')`
    display: none;

    ${minBreakpoint.md`
        display: block;
    `}
`;

const AlignmentContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;

    ${minBreakpoint.md`
        padding:0 20px;
    `}
`;

const NavCareerContainer = styled('div')`
    background-color: ${fromTheme('colors', 'grey500')};
    padding: 20px 0;
`;

const NavMainDesktop: React.FC = () => (
    <StyledNavMainDesktop>
        <AlignmentContainer>
            <NavOetkerGroup identifier="desktop" />
            <LanguageSwitch />
        </AlignmentContainer>

        <NavCareerContainer>
            <Container>
                <NavCareer identifier="desktop" />
            </Container>
        </NavCareerContainer>
    </StyledNavMainDesktop>
);

export default NavMainDesktop;
