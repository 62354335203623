import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { breakpoints, fromTheme, useMedia } from '../../../src/utils';
import headerImgPathMobile from '../../assets/images/logo-dr-august-oetker-kg-1x.png';
import headerImgPathTablet from '../../assets/images/logo-dr-august-oetker-kg-2x.png';
import Picture from '../Picture/Picture';
import NavMainDesktop from './NavMainDesktop/NavMainDesktop';
import NavMainMobile from './NavMainMobile/NavMainMobile';
import Backdrop from '../Backdrop/Backdrop';
import Hamburger from './Hamburger/Hamburger';

const StyledHeader = styled('header')`
    height: 85px;
    overflow: hidden;
    text-align: center;
    border-bottom: 1px solid ${fromTheme('colors', 'grey800')};

    @media (min-width: ${breakpoints.md}px) {
        height: 230px;
        border-bottom: 0;
    }
`;

const StyledLink = styled(Link)`
    display: inline-block;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    min-width: 130px;
    height: fit-content;
    width: 35%;
    max-width: 155px;
    margin: auto;

    @media (min-width: ${breakpoints.md}px) {
        max-width: 330px;
    }
`;

const StyledPicture = styled(Picture)`
    display: block;

    img {
        width: 100%;
    }
`;

const fallBackImgCss = css`
    max-width: 330px;
    width: 100%;
    transform: translate(-50%, 50%);
    margin-left: 50%;
`;

const Header: React.FC = ({ ...props }) => {
    const [showMobileNav, setShowMobileNav] = useState(false);
    const isLargerMD = useMedia(`(min-width: ${breakpoints.md}px)`, true);

    return (
        <React.Fragment>
            <StyledHeader {...props}>
                {!isLargerMD && (
                    <Hamburger onClick={() => setShowMobileNav(true)} />
                )}
                <StyledLink to="/">
                    <StyledPicture
                        alt="Dr. August Oetker KG"
                        imgPathMobile={headerImgPathMobile}
                        imgPathTablet={headerImgPathTablet}
                        fallBackImgCss={fallBackImgCss}
                        lazyload={false}
                    />
                </StyledLink>
            </StyledHeader>
            {isLargerMD ? (
                <NavMainDesktop aria-label="desktop-nav" />
            ) : (
                <div aria-label="mobile-nav">
                    <NavMainMobile
                        isOpen={showMobileNav}
                        toggle={() => setShowMobileNav(false)}
                    />
                    <Backdrop
                        data-testid="header-backdrop"
                        isOpen={showMobileNav}
                        onClick={() => setShowMobileNav(false)}
                        data-is-visible={showMobileNav}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default Header;
