import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import CompanyLogo from '../../../assets/images/company-logos/radeberger-gruppe-x2.png';
import stagePathMobile from '../../../assets/images/radeberger/stage/keyvisual-radeberger-1x.jpg';
import stagePathTablet from '../../../assets/images/radeberger/stage/keyvisual-radeberger-2x.jpg';
import teaser1ImgPathMobile from '../../../assets/images/radeberger/teaser1-radeberger-x1.jpg';
import teaser1ImgPathTablet from '../../../assets/images/radeberger/teaser1-radeberger-x2.jpg';
import teaser2ImgPathMobile from '../../../assets/images/radeberger/teaser2-radeberger-x1.jpg';
import teaser2ImgPathTablet from '../../../assets/images/radeberger/teaser2-radeberger-x2.jpg';
import teaser3ImgPathMobile from '../../../assets/images/radeberger/teaser3-radeberger-x1.jpg';
import teaser3ImgPathTablet from '../../../assets/images/radeberger/teaser3-radeberger-x2.jpg';
import BenefitList from '../../../components/BenefitList/BenefitList';
import {
    FlexContainer,
    FlexItem,
} from '../../../components/container/FlexContainer/FlexContainer';
import { Container } from '../../../components/container/PageContainer/PageContainer';
import Section from '../../../components/container/Section/Section';
import Headline from '../../../components/Headline/Headline';
import Picture from '../../../components/Picture/Picture';
import Stage from '../../../components/Stage/Stage';
import { useJobData } from '../../../provider/JobDataProvider/JobDataProvider';
import { usePopup } from '../../../provider/PopupProvider/PopupProvider';
import { Benefits, CompanyDetailProps } from '../../../types/common';
import { breakpoints, fromTheme } from '../../../utils';
import JobSection from '../../../components/JobSection/JobSection';
import messages from '../CompanyDetail.messages';
import PageHead from '../../../components/base/PageHead/PageHead';
import messagesCompanySpecific from './Radeberger.messages';

const FlexItemCenterContent = styled(FlexItem)`
    text-align: center;
`;

const LogoImage = styled(Picture)`
    max-width: 300px;
    margin: auto;
    margin-bottom: 25px;
    display: inline-block;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 0;
        display: block;
    }
`;

const TextLink = styled('button')`
    color: ${fromTheme('colors', 'floatingText')};
    display: inline;
    border: 0;
    font-size: ${fromTheme('fontSize', 'base')};
    font-weight: ${fromTheme('fontWeight', 'regular')};
    font-family: ${fromTheme('fontFamily', 'base')};
    position: relative;
    cursor: pointer;
    margin-left: 4px;
    outline: none;
    text-decoration: underline;
    transition: color 0.1s;
    background-color: transparent;

    @media (min-width: ${breakpoints.md}px) {
        font-size: ${fromTheme('fontSize', 'base', 'md')};
    }

    &:hover {
        color: ${fromTheme('colors', 'blue500')};
    }
`;

const PopupInner = styled('div')`
    padding: ${fromTheme('popup', 'textPopupInnerPadding')};
`;

const PopupHeadline = styled(Headline)`
    margin-bottom: 40px;
`;

const StyledH2Headline = styled(Headline)`
    word-break: break-word;
    margin-bottom: 25px;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 45px;
        word-break: initial;
    }
`;

const Radeberger: React.FC<CompanyDetailProps> = ({ companyId, ...props }) => {
    const intl = useIntl();
    const { allJobs, getCompanyJobs } = useJobData();
    const { sortedJobs } = getCompanyJobs(allJobs, companyId);
    const { togglePopup } = usePopup();
    const companyBenefits: Benefits[] = [
        'flexibleWorkingHours',
        'homeoffice',
        'employeeRestaurant',
        'companyPensionScheme',
        'healthPromotionMeasures',
        'sportsOffers',
        'companyDoctor',
        'employeeEvents',
        'parking',
        'employeeDiscounts',
        'employeeMobilePhone',
        'trainings',
        'companyCar',
        'personalFeedback',
        'sustainability',
        'employeeRecommendations',
        'groupAccidentInsurance',
    ];
    const section3 = [
        {
            imgPathMobile: teaser1ImgPathMobile,
            imgPathTablet: teaser1ImgPathTablet,
            headline: (
                <FormattedMessage
                    {...messagesCompanySpecific.section3Teaser1Headline}
                />
            ),
            text: (
                <FormattedHTMLMessage
                    {...messagesCompanySpecific.section3Teaser1Text}
                />
            ),
            popupText: (
                <FormattedHTMLMessage
                    {...messagesCompanySpecific.section3Teaser1PopupText}
                />
            ),
        },
        {
            imgPathMobile: teaser2ImgPathMobile,
            imgPathTablet: teaser2ImgPathTablet,
            headline: (
                <FormattedMessage
                    {...messagesCompanySpecific.section3Teaser2Headline}
                />
            ),
            text: (
                <FormattedHTMLMessage
                    {...messagesCompanySpecific.section3Teaser2Text}
                />
            ),
            popupText: (
                <FormattedHTMLMessage
                    {...messagesCompanySpecific.section3Teaser2PopupText}
                />
            ),
        },
        {
            imgPathMobile: teaser3ImgPathMobile,
            imgPathTablet: teaser3ImgPathTablet,
            headline: (
                <FormattedMessage
                    {...messagesCompanySpecific.section3Teaser3Headline}
                />
            ),
            text: (
                <FormattedHTMLMessage
                    {...messagesCompanySpecific.section3Teaser3Text}
                />
            ),
            popupText: (
                <FormattedHTMLMessage
                    {...messagesCompanySpecific.section3Teaser3PopupText}
                />
            ),
        },
    ];

    return (
        <>
            <PageHead
                pageTitle={intl.formatMessage(
                    messagesCompanySpecific.pageTitle
                )}
                metaDescription={intl.formatMessage(
                    messagesCompanySpecific.metaDescription
                )}
            />
            <Stage
                borderColor="radeberger"
                imgPathMobile={stagePathMobile}
                imgPathTablet={stagePathTablet}
                alt="Radeberger"
            />
            <Section background="white">
                <Container>
                    <FlexContainer columnType="2-1" reverseDesktop>
                        <FlexItemCenterContent>
                            <LogoImage
                                imgPathMobile={CompanyLogo}
                                alt="Radeberger"
                            />
                        </FlexItemCenterContent>
                        <FlexItem>
                            <Headline as="h1">
                                <FormattedMessage
                                    {...messagesCompanySpecific.headline}
                                />
                            </Headline>
                            <p>
                                <FormattedMessage
                                    {...messagesCompanySpecific.firstSectionText1}
                                />
                            </p>
                        </FlexItem>
                    </FlexContainer>
                </Container>
            </Section>

            <Section background="blue">
                <Container>
                    <StyledH2Headline as="h2" size="small">
                        <FormattedMessage {...messages.benefitsHeadline} />
                    </StyledH2Headline>
                    <BenefitList companyBenefits={companyBenefits} />
                </Container>
            </Section>

            {/* 3 column: text + image + text popup */}
            <Section background="white">
                <Container>
                    <StyledH2Headline as="h2" size="small">
                        <FormattedMessage
                            {...messagesCompanySpecific.section3HeadlineMain}
                        />
                    </StyledH2Headline>
                    <FlexContainer columnType="third">
                        {section3.map((flexitem, index) => (
                            <FlexItem key={index}>
                                <Picture
                                    alt={intl.formatMessage(
                                        messagesCompanySpecific.section3Teaser1Headline
                                    )}
                                    imgPathTablet={flexitem.imgPathTablet}
                                    imgPathMobile={flexitem.imgPathMobile}
                                />
                                <Headline as="h3" size="smallest">
                                    {flexitem.headline}
                                </Headline>
                                <p>
                                    {flexitem.text}

                                    <TextLink
                                        onClick={() => {
                                            togglePopup(
                                                <PopupInner>
                                                    <PopupHeadline
                                                        as="h3"
                                                        size="smallest"
                                                    >
                                                        {flexitem.headline}
                                                    </PopupHeadline>
                                                    <p>{flexitem.popupText}</p>
                                                </PopupInner>
                                            );
                                        }}
                                    >
                                        <FormattedMessage
                                            {...messages.readMore}
                                        />
                                    </TextLink>
                                </p>
                            </FlexItem>
                        ))}
                    </FlexContainer>
                </Container>
            </Section>

            <hr />

            <JobSection
                headlineType="h1"
                jobs={sortedJobs[intl.locale]}
                jobAmountToShow={7}
            />
        </>
    );
};

export default Radeberger;
