import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'drOetker.pageTitle',
        defaultMessage: 'drOetker.pageTitle',
    },
    metaDescription: {
        id: 'drOetker.metaDescription',
        defaultMessage: 'drOetker.metaDescription',
    },
    headline: {
        id: 'drOetker.headline',
        defaultMessage: 'drOetker.headline',
    },
    section1Text1: {
        id: 'drOetker.section1.text1',
        defaultMessage: 'drOetker.section1.text1',
    },
    section1Text2: {
        id: 'drOetker.section1.text2',
        defaultMessage: 'drOetker.section1.text2',
    },
    section1Text3: {
        id: 'drOetker.section1.text3',
        defaultMessage: 'drOetker.section1.text3',
    },
    section1Text4: {
        id: 'drOetker.section1.text4',
        defaultMessage: 'drOetker.section1.text4',
    },
    contactHeadline: {
        id: 'drOetker.contactSection.headline',
        defaultMessage: 'drOetker.contactSection.headline',
    },
    personCard1Headline: {
        id: 'drOetker.personCard1.headline',
        defaultMessage: 'drOetker.personCard1.headline',
    },
    personCard1Text: {
        id: 'drOetker.personCard1.text',
        defaultMessage: 'drOetker.personCard1.text',
    },
    section3HeadlineMain: {
        id: 'drOetker.section3.headlineMain',
        defaultMessage: 'drOetker.section3.headlineMain',
    },
    section3Teaser1Headline: {
        id: 'drOetker.section3.teaser1.headline',
        defaultMessage: 'drOetker.section3.teaser1.headline',
    },
    section3Teaser1Text: {
        id: 'drOetker.section3.teaser1.text',
        defaultMessage: 'drOetker.section3.teaser1.text',
    },
    section3Teaser1PopupText: {
        id: 'drOetker.section3.teaser1.popupText',
        defaultMessage: 'drOetker.section3.teaser1.popupText',
    },
    section3Teaser2Headline: {
        id: 'drOetker.section3.teaser2.headline',
        defaultMessage: 'drOetker.section3.teaser2.headline',
    },
    section3Teaser2Text: {
        id: 'drOetker.section3.teaser2.text',
        defaultMessage: 'drOetker.section3.teaser2.text',
    },
    section3Teaser2PopupText: {
        id: 'drOetker.section3.teaser2.popupText',
        defaultMessage: 'drOetker.section3.teaser2.popupText',
    },
    section3Teaser3Headline: {
        id: 'drOetker.section3.teaser3.headline',
        defaultMessage: 'drOetker.section3.teaser3.headline',
    },
    section3Teaser3Text: {
        id: 'drOetker.section3.teaser3.text',
        defaultMessage: 'drOetker.section3.teaser3.text',
    },
    section3Teaser3PopupText: {
        id: 'drOetker.section3.teaser3.popupText',
        defaultMessage: 'drOetker.section3.teaser3.popupText',
    },
});

export default messages;
