import { defineMessages } from 'react-intl';

const messages = defineMessages({
    allLevel: {
        id: 'filter.joblevel.all',
        defaultMessage: 'filter.joblevel.all',
    },
    pupils: {
        id: 'filter.joblevel.pupils',
        defaultMessage: 'filter.joblevel.pupils',
    },
    students: {
        id: 'filter.joblevel.students',
        defaultMessage: 'filter.joblevel.students',
    },
    youngProfessionals: {
        id: 'filter.joblevel.youngProfessionals',
        defaultMessage: 'filter.joblevel.youngProfessionals',
    },
    professionals: {
        id: 'filter.joblevel.professionals',
        defaultMessage: 'filter.joblevel.professionals',
    },
    managers: {
        id: 'filter.joblevel.managers',
        defaultMessage: 'filter.joblevel.managers',
    },
    allLocations: {
        id: 'filter.locations.all',
        defaultMessage: 'filter.locations.all',
    },
});

export default messages;
