import React, {
    createContext,
    FC,
    useContext,
    useState,
    useEffect,
} from 'react';
import { useLocation } from 'react-router';
import {
    hasCookie,
    setCookieTier,
    track,
    defaultCookieValue,
} from '../../utils';

interface AnalyticsProviderState {
    isCookieBannerVisible: boolean;
    closeBanner: () => void;
    saveSettings: (cookieValue: number) => void;
}

export const AnalyticsContext = createContext<
    AnalyticsProviderState | undefined
>(undefined);

const AnalyticsProvider: FC = ({ children }) => {
    const { pathname } = useLocation();
    (window as any).dataLayer = (window as any).dataLayer || [];

    const trackPageView = (contentViewName: string) => {
        (window as any).dataLayer.push({
            event: 'content-view',
            contentName: contentViewName,
            contentViewName:
                contentViewName ||
                document.location.href.slice(document.location.origin.length),
        });
    };

    // cookie banner
    const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(
        !hasCookie()
    );

    const closeBanner = () => {
        setIsCookieBannerVisible(false);
        track('cookie banner');
    };

    /*
     * cookie settings
     */

    const saveSettings = (cookieValue: number) => {
        setCookieTier(cookieValue);
        track('cookie settings');
    };

    // set the initial cookie
    useEffect(() => {
        if (!hasCookie()) {
            setCookieTier(defaultCookieValue);
        }
    }, []);

    // page view tracking
    useEffect(() => {
        trackPageView(pathname);
    }, [pathname]);

    return (
        <AnalyticsContext.Provider
            value={{
                isCookieBannerVisible,
                closeBanner,
                saveSettings,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};

export const useAnalytics = () => {
    const context = useContext(AnalyticsContext);

    if (!context) {
        throw new Error('useAnalytics must be used within a AnalyticsProvider');
    }

    return context;
};

export default AnalyticsProvider;
