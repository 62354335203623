import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';

// company logos
import conditoreiCoppenrathWiese from '../../assets/images/company-logos/coppenrath-wiese-x1.png';
import conditoreiCoppenrathWiese2x from '../../assets/images/company-logos/coppenrath-wiese-x2.png';
import drOetker from '../../assets/images/company-logos/droetker-x1.png';
import drOetker2x from '../../assets/images/company-logos/droetker-x2.png';
import oediv from '../../assets/images/company-logos/oediv-x1.png';
import oediv2x from '../../assets/images/company-logos/oediv-x2.png';
import oetkerDigital from '../../assets/images/company-logos/oetker-digital-x1.png';
import oetkerDigital2x from '../../assets/images/company-logos/oetker-digital-x2.png';
import radebergerGruppe from '../../assets/images/company-logos/radeberger-gruppe-x1.png';
import radebergerGruppe2x from '../../assets/images/company-logos/radeberger-gruppe-x2.png';

// components
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import {
    FlexContainer,
    FlexItem,
} from '../../components/container/FlexContainer/FlexContainer';
import { Container } from '../../components/container/PageContainer/PageContainer';
import Section from '../../components/container/Section/Section';
import Headline from '../../components/Headline/Headline';
import routes from '../../routes.messages';
import messages from './CompanyOverview.messages';
import { companyRoutes } from '../../routes.messages';
import styled from 'styled-components';
import { isFeatureSupport, breakpoints } from '../../utils';
import PageHead from '../../components/base/PageHead/PageHead';

const StyledFlexContainer = styled(FlexContainer)`
    display: inline-block;

    @supports (flex-wrap: wrap) {
        display: flex;
        flex-wrap: wrap;
    }
`;

// styles only for IE, because of ts-styled error when implementing @support under a media query
// see: https://github.com/microsoft/typescript-styled-plugin/issues/99
const StyledFlexItem = isFeatureSupport('pictureTag')
    ? styled(FlexItem)`
          width: 100%;
      `
    : styled('div')`
          display: inline-block;
          width: 30%;

          @media (min-width: ${breakpoints.md}px) {
              flex: initial;
              margin-right: 3%;
          }
      `;

const CompanyOverview = () => {
    const intl = useIntl();
    const { pathname } = useLocation();
    const isLastCharOfUrlSlash = pathname.charAt(pathname.length - 1) === '/';

    const companies = [
        {
            imgPath: drOetker,
            img2xPath: drOetker2x,
            imgAlt: 'Dr. Oetker',
            text: intl.formatMessage(messages.drOetker),
            href:
                intl.formatMessage(routes.companyOverview) +
                companyRoutes.drOetker.route,
        },
        {
            imgPath: radebergerGruppe,
            img2xPath: radebergerGruppe2x,
            imgAlt: 'Radeberger Gruppe',
            text: intl.formatMessage(messages.radebergerGruppe),
            href:
                intl.formatMessage(routes.companyOverview) +
                companyRoutes.radebergerGroup.route,
        },
        {
            imgPath: oediv,
            img2xPath: oediv2x,
            imgAlt: 'OEDIV',
            text: intl.formatMessage(messages.oediv),
            href:
                intl.formatMessage(routes.companyOverview) +
                companyRoutes.oediv.route,
        },
        {
            imgPath: conditoreiCoppenrathWiese,
            img2xPath: conditoreiCoppenrathWiese2x,
            imgAlt: 'Coppenrath Wiese',
            text: intl.formatMessage(messages.conditoreiCoppenrathWiese),
            href:
                intl.formatMessage(routes.companyOverview) +
                companyRoutes.ccw.route,
        },
        {
            imgPath: oetkerDigital,
            img2xPath: oetkerDigital2x,
            imgAlt: 'Oetker Digital',
            text: intl.formatMessage(messages.oetkerDigital),
            href:
                intl.formatMessage(routes.companyOverview) +
                companyRoutes.od.route,
        },
    ];

    return (
        <Section>
            <PageHead
                pageTitle={intl.formatMessage(messages.pageTitle)}
                metaDescription={intl.formatMessage(messages.metaDescription)}
            />
            <Container>
                <Headline as="h1">
                    <FormattedMessage {...messages.headline} />
                </Headline>

                <StyledFlexContainer columnType="third">
                    {companies.map((company) => (
                        <StyledFlexItem key={company.imgAlt}>
                            <CompanyCard
                                imgPath={company.imgPath}
                                img2xPath={company.img2xPath}
                                imgAlt={company.imgAlt}
                                text={company.text}
                                to={
                                    (isLastCharOfUrlSlash ? '../' : '') +
                                    company.href
                                }
                            />
                        </StyledFlexItem>
                    ))}
                </StyledFlexContainer>
            </Container>
        </Section>
    );
};

export default CompanyOverview;
