import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ListArrow } from '../../assets/icons/list-arrow.svg';
import { useJobContent } from '../../provider/JobDataProvider/JobContentProvider';
import { fromTheme } from '../../utils';

interface AccordionItemProps {
    headerContent: ReactNode;
    children: ReactNode;
    onClick: () => void;
    openAccordionId: number | string;
    jobId: number;
}

const ItemContainer = styled('div')`
    border-bottom: 1px solid ${fromTheme('colors', 'grey500')};
`;

const AccordionHeader = styled('div')`
    cursor: pointer;
    position: relative;
`;

const AccordionContent = styled('div')<{ height: number }>`
    height: ${(props) => props.height}px;
    transition: all 0.3s;
    overflow: hidden;
`;

const AccordionContentInner = styled('div')``;

const ListArrowContainer = styled('span')<{ isFlipped: boolean }>`
    display: block;
    position: absolute;
    left: 0;
    top: 28px;
    transition: transform 0.2s;
    transform: rotate(-90deg);

    ${(props) =>
        props.isFlipped &&
        css`
            transform: rotate(0deg);
        `}
`;

const AccordionItem: React.FC<AccordionItemProps> = ({
    headerContent,
    children,
    onClick,
    openAccordionId,
    jobId,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [accordionHeight, setAccordionHeight] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const { contentRequestStatus } = useJobContent();

    useEffect(() => {
        // open accordion
        if (openAccordionId === jobId) {
            setAccordionHeight((ref.current as HTMLDivElement).clientHeight);
            setIsOpen(true);
        } else {
            // close accordion
            setAccordionHeight(0);
            setIsOpen(false);
        }
    }, [contentRequestStatus, isOpen, openAccordionId, jobId]);

    return (
        <ItemContainer {...props}>
            <AccordionHeader
                onClick={() => {
                    // request specific jobContent when accordion item will be opened
                    if (!isOpen) {
                        onClick();
                    }
                }}
            >
                <ListArrowContainer isFlipped={isOpen}>
                    <ListArrow />
                </ListArrowContainer>
                {headerContent}
            </AccordionHeader>

            <AccordionContent
                height={accordionHeight}
                data-testid="accordion-content"
                data-is-hidden={accordionHeight === 0 ? 'hidden' : ''}
            >
                <AccordionContentInner ref={ref}>
                    {children}
                </AccordionContentInner>
            </AccordionContent>
        </ItemContainer>
    );
};

export default AccordionItem;
