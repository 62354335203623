import React from 'react';
import styled from 'styled-components';
import { JobProps } from '../../../types/common';
import { breakpoints, fromTheme } from '../../../utils';
import {
    FlexContainer,
    FlexItem,
} from '../../container/FlexContainer/FlexContainer';
import Tag from '../../Tag/Tag';

// this is a variant of the job list item
interface JobListItemAccordionProps {
    job: JobProps;
}

const StyledFlexContainer = styled(FlexContainer)`
    font-size: ${fromTheme('fontSize', 'headlineSmall')};
    padding: 25px 0 25px 20px;
    list-style-type: none;
    position: relative;
    cursor: pointer;

    @media (min-width: ${breakpoints.md}px) {
        font-size: ${fromTheme('fontSize', 'headlineSmall', 'md')};
    }
`;

const TitleContainer = styled(FlexItem)`
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 0;
    }
`;

const Title = styled('span')`
    margin-right: 15px;

    @media (min-width: ${breakpoints.md}px) {
        margin-right: 25px;
    }
`;

const TagContainer = styled(FlexContainer)`
    margin-right: 3px;
    display: flex;

    > div {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const JobListItemAccordion: React.FC<JobListItemAccordionProps> = ({
    job,
    ...props
}) => {
    const hasCompanyName =
        job.company !== undefined && job.company.name !== undefined;

    return (
        <StyledFlexContainer
            columnType="2-1"
            {...props}
            data-testid="joblistitem-accordion"
        >
            <TitleContainer>
                <Title>{job.title}</Title>
                {job.isNew && <Tag isNew>New</Tag>}
            </TitleContainer>
            <FlexItem>
                <TagContainer columnType="half">
                    <FlexItem>
                        {hasCompanyName && <Tag>{job.company.name}</Tag>}
                    </FlexItem>
                    <FlexItem>
                        <Tag>{job.location}</Tag>
                    </FlexItem>
                </TagContainer>
            </FlexItem>
        </StyledFlexContainer>
    );
};

export default JobListItemAccordion;
