import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'companyDetail.pageTitle',
        defaultMessage: 'companyDetail.pageTitle',
    },
    metaDescription: {
        id: 'companyDetail.metaDescription',
        defaultMessage: 'companyDetail.metaDescription',
    },
    headline: {
        id: 'companyDetail.headline',
        defaultMessage: 'companyDetail.headline',
    },
    firstSectionText: {
        id: 'companyDetail.firstSection.text',
        defaultMessage: 'companyDetail.firstSection.text',
    },
    firstSectionButton: {
        id: 'companyDetail.firstSection.button',
        defaultMessage: 'companyDetail.firstSection.button',
    },
    benefitsHeadline: {
        id: 'companyDetail.benefits.headline',
        defaultMessage: 'companyDetail.benefits.headline',
    },
    contactHeadline: {
        id: 'companyDetail.contactSection.headline',
        defaultMessage: 'companyDetail.contactSection.headline',
    },
    personCardHeadline: {
        id: 'companyDetail.personCard.headline',
        defaultMessage: 'companyDetail.personCard.headline',
    },
    personCardText: {
        id: 'companyDetail.personCard.text',
        defaultMessage: 'companyDetail.personCard.text',
    },
    readMore: {
        id: 'companyDetail.textLink.openPopup',
        defaultMessage: 'companyDetail.textLink.openPopup',
    },
    contactImg: {
        id: 'companyDetail.personCard.image.alt',
        defaultMessage: 'companyDetail.personCard.image.alt',
    },
    logoAlt: {
        id: 'companyDetail.logoAlt',
        defaultMessage: 'companyDetail.logoAlt',
    },
});

export default messages;
