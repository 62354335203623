import React from 'react';
import styled, { css } from 'styled-components';
import { fromTheme, breakpoints } from '../../utils';
import Picture from '../Picture/Picture';

export type borderColorType = 'radeberger' | 'coppenrath' | 'oetkerDigital';

interface PictureContainerProps {
    borderColor?: borderColorType;
}

interface StageProps extends PictureContainerProps {
    alt: string;
    imgPathMobile: string;
    imgPathTablet?: string;
    imgPathDesktop?: string;
}

const PictureContainer = styled('div')<PictureContainerProps>`
    ${(props) =>
        props.borderColor &&
        css`
            border-bottom: 15px solid ${fromTheme('colors', props.borderColor)};
        `}
`;

const fallBackImgCss = css`
    display: block;
`;

const StyledPicture = styled(Picture)`
    min-height: 100px;

    @media (min-width: ${breakpoints.md}px) {
        min-height: 230px;
    }

    @media (min-width: ${breakpoints.lg}px) {
        min-height: 310px;
    }

    @media (min-width: ${breakpoints.xl}px) {
        min-height: 370px;
    }
`;

const Stage: React.FC<StageProps> = ({
    borderColor,
    alt,
    imgPathMobile,
    imgPathTablet,
    imgPathDesktop,
    ...props
}) => {
    return (
        <PictureContainer borderColor={borderColor} {...props}>
            <StyledPicture
                alt={alt}
                imgPathMobile={imgPathMobile}
                imgPathTablet={imgPathTablet}
                imgPathDesktop={imgPathDesktop}
                lazyload={false}
                fallBackImgCss={fallBackImgCss}
            />
        </PictureContainer>
    );
};

export default Stage;
