import { defineMessages } from 'react-intl';

const messages = defineMessages({
    navMobileText: {
        id: 'navMobile.text',
        defaultMessage: 'navMobile.text',
    },
});

export default messages;
