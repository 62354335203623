import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { breakpoints } from '../../utils';

import { ReactComponent as FlexibleWorkingHours } from '../../assets/icons/benefits/flexible-working-hours.svg';
import { ReactComponent as Accessibility } from '../../assets/icons/benefits/accessibility.svg';
import { ReactComponent as CapitalAccumulationBenefits } from '../../assets/icons/benefits/capital-accumulation-benefits.svg';
import { ReactComponent as ChildCare } from '../../assets/icons/benefits/child-care.svg';
import { ReactComponent as Coaching } from '../../assets/icons/benefits/coaching.svg';
import { ReactComponent as CompanyCar } from '../../assets/icons/benefits/company-car.svg';
import { ReactComponent as CompanyDoctor } from '../../assets/icons/benefits/company-doctor.svg';
import { ReactComponent as CompanyPensionScheme } from '../../assets/icons/benefits/company-pension-scheme.svg';

import { ReactComponent as EmployeeMobilePhone } from '../../assets/icons/benefits/employee-mobile-phone.svg';
import { ReactComponent as FoodAllowance } from '../../assets/icons/benefits/food-allowance.svg';
import { ReactComponent as InternationalTeams } from '../../assets/icons/benefits/international-teams.svg';
import { ReactComponent as PeerProgram } from '../../assets/icons/benefits/peer-program.svg';
import { ReactComponent as Sustainability } from '../../assets/icons/benefits/sustainability.svg';
import { ReactComponent as EmployeeRecommendations } from '../../assets/icons/benefits/employee-recommendations.svg';
import { ReactComponent as GoodTrafficConnections } from '../../assets/icons/benefits/good-traffic-connections.svg';
import { ReactComponent as Internet } from '../../assets/icons/benefits/internet.svg';
import { ReactComponent as PersonalFeedback } from '../../assets/icons/benefits/personal-feedback.svg';
import { ReactComponent as Trainings } from '../../assets/icons/benefits/trainings.svg';
import { ReactComponent as EmergencyChildcare } from '../../assets/icons/benefits/emergency-childcare.svg';
import { ReactComponent as EmployeeRestaurant } from '../../assets/icons/benefits/employee-restaurant.svg';
import { ReactComponent as GroupAccidentInsurance } from '../../assets/icons/benefits/group-accident-insurance.svg';
import { ReactComponent as Jobticket } from '../../assets/icons/benefits/jobticket.svg';
import { ReactComponent as RelocationService } from '../../assets/icons/benefits/relocation-service.svg';
import { ReactComponent as EmployeeDiscounts } from '../../assets/icons/benefits/employee-discounts.svg';
import { ReactComponent as FamilyService } from '../../assets/icons/benefits/family-service.svg';
import { ReactComponent as HealthPromotionMeasures } from '../../assets/icons/benefits/health-promotion-measures.svg';
import { ReactComponent as KickertableFifa } from '../../assets/icons/benefits/kickertable-fifa.svg';
import { ReactComponent as SchoolVacationChildcare } from '../../assets/icons/benefits/school-vacation-childcare.svg';
import { ReactComponent as EmployeeEvents } from '../../assets/icons/benefits/employee-events.svg';
import { ReactComponent as Homeoffice } from '../../assets/icons/benefits/homeoffice.svg';
import { ReactComponent as Parking } from '../../assets/icons/benefits/parking.svg';
import { ReactComponent as SportsOffers } from '../../assets/icons/benefits/sports-offers.svg';
import { Benefits } from '../../types/common';

interface BenefitListProps {
    companyBenefits: Benefits[];
}

const StyledBenefitList = styled('ul')`
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 0;
    vertical-align: middle;

    @media (min-width: ${breakpoints.md}px) {
        columns: 3;
    }
`;

const StyledBenefitListItem = styled('li')`
    margin-bottom: 3px;
    vertical-align: middle;

    @media (min-width: ${breakpoints.lg}px) {
        margin-bottom: 20px;
    }
`;

const StyledIcon = styled('svg')`
    display: inline-block;
    vertical-align: middle;
    margin-right: 35px;
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 0;
        margin-top: 10px;
    }

    @media (min-width: 876px) {
        margin-top: 5px;
        margin-bottom: 5px;
    }
`;

const IconText = styled('span')`
    display: inline-block;
    width: 70%;
    vertical-align: middle;
`;

const BenefitList: React.FC<BenefitListProps> = ({
    companyBenefits,
    ...props
}) => {
    const intl = useIntl();

    const allBenefits: {
        [key: string]: {
            icon: React.FC<React.SVGProps<SVGSVGElement>>;
            textKey: string;
        };
    } = {
        accessibility: {
            icon: Accessibility,
            textKey: 'accessibility',
        },
        companyDoctor: {
            icon: CompanyDoctor,
            textKey: 'companyDoctor',
        },
        employeeMobilePhone: {
            icon: EmployeeMobilePhone,
            textKey: 'employeeMobilePhone',
        },
        foodAllowance: {
            icon: FoodAllowance,
            textKey: 'foodAllowance',
        },
        internationalTeams: {
            icon: InternationalTeams,
            textKey: 'internationalTeams',
        },
        peerProgram: {
            icon: PeerProgram,
            textKey: 'peerProgram',
        },
        sustainability: {
            icon: Sustainability,
            textKey: 'sustainability',
        },
        capitalAccumulationBenefits: {
            icon: CapitalAccumulationBenefits,
            textKey: 'capitalAccumulationBenefits',
        },
        companyPensionScheme: {
            icon: CompanyPensionScheme,
            textKey: 'companyPensionScheme',
        },
        employeeRecommendations: {
            icon: EmployeeRecommendations,
            textKey: 'employeeRecommendations',
        },
        goodTrafficConnections: {
            icon: GoodTrafficConnections,
            textKey: 'goodTrafficConnections',
        },
        internet: {
            icon: Internet,
            textKey: 'internet',
        },
        personalFeedback: {
            icon: PersonalFeedback,
            textKey: 'personalFeedback',
        },
        trainings: {
            icon: Trainings,
            textKey: 'trainings',
        },
        childCare: {
            icon: ChildCare,
            textKey: 'childCare',
        },
        emergencyChildcare: {
            icon: EmergencyChildcare,
            textKey: 'emergencyChildcare',
        },
        employeeRestaurant: {
            icon: EmployeeRestaurant,
            textKey: 'employeeRestaurant',
        },
        groupAccidentInsurance: {
            icon: GroupAccidentInsurance,
            textKey: 'groupAccidentInsurance',
        },
        jobticket: {
            icon: Jobticket,
            textKey: 'jobticket',
        },
        relocationService: {
            icon: RelocationService,
            textKey: 'relocationService',
        },
        coaching: {
            icon: Coaching,
            textKey: 'coaching',
        },
        employeeDiscounts: {
            icon: EmployeeDiscounts,
            textKey: 'employeeDiscounts',
        },
        familyService: {
            icon: FamilyService,
            textKey: 'familyService',
        },
        healthPromotionMeasures: {
            icon: HealthPromotionMeasures,
            textKey: 'healthPromotionMeasures',
        },
        kickertableFifa: {
            icon: KickertableFifa,
            textKey: 'kickertableFifa',
        },
        schoolVacationChildcare: {
            icon: SchoolVacationChildcare,
            textKey: 'schoolVacationChildcare',
        },
        companyCar: {
            icon: CompanyCar,
            textKey: 'companyCar',
        },
        employeeEvents: {
            icon: EmployeeEvents,
            textKey: 'employeeEvents',
        },
        flexibleWorkingHours: {
            icon: FlexibleWorkingHours,
            textKey: 'flexibleWorkingHours',
        },
        homeoffice: {
            icon: Homeoffice,
            textKey: 'homeoffice',
        },
        parking: {
            icon: Parking,
            textKey: 'parking',
        },
        sportsOffers: {
            icon: SportsOffers,
            textKey: 'sportsOffers',
        },
    };

    const showBenefits: any[] = [];

    // map benefit data for specific company benefits
    for (let i = 0; i < companyBenefits.length; i++) {
        const benefitKey = companyBenefits[i];

        showBenefits.push(allBenefits[benefitKey]);
    }

    return (
        <StyledBenefitList>
            {showBenefits.map((benefit) => (
                <StyledBenefitListItem key={benefit.textKey}>
                    <StyledIcon as={benefit.icon} />
                    <IconText>
                        {intl.messages['benefit.' + benefit.textKey]}
                    </IconText>
                </StyledBenefitListItem>
            ))}
        </StyledBenefitList>
    );
};

export default BenefitList;
