import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes, { companyRoutes } from '../../../routes.messages';
import CompanyOverview from '../../../views/CompanyOverview/CompanyOverview';
import CookieSettings from '../../../views/CookieSettings/CookieSettings';
import Home from '../../../views/Home/Home';
import Jobs from '../../../views/Jobs/Jobs';
import { Company } from '../../../types/common';

interface CompanyRoutes {
    [company: string]: { route: string; dvinciId: Company };
}

const Routes: React.FC = ({ ...props }) => {
    const intl = useIntl();
    const { pathname } = useLocation();
    const noLanguageSelected =
        !pathname.includes('/de/') && !pathname.includes('/en/');

    return (
        <Switch>
            <Route exact path={`/${intl.locale}/`}>
                <Home />
            </Route>
            <Route
                exact
                path={`/${intl.locale}/` + intl.formatMessage(routes.jobs)}
            >
                <Jobs />
            </Route>
            <Route
                exact
                path={
                    `/${intl.locale}/` +
                    intl.formatMessage(routes.companyOverview)
                }
            >
                <CompanyOverview />
            </Route>
            {Object.keys(companyRoutes as CompanyRoutes).map(
                (company: string) => {
                    const route = companyRoutes[company].route;
                    const companyId = companyRoutes[company].dvinciId;
                    const CompanyComponent = companyRoutes[company].component;

                    return (
                        <Route
                            exact
                            key={
                                intl.formatMessage(routes.companyOverview) +
                                route
                            }
                            path={
                                `/${intl.locale}/` +
                                intl.formatMessage(routes.companyOverview) +
                                route
                            }
                        >
                            <CompanyComponent companyId={companyId} />
                        </Route>
                    );
                }
            )}
            <Route
                exact
                path={
                    `/${intl.locale}/` +
                    intl.formatMessage(routes.cookieSettings)
                }
            >
                <CookieSettings />
            </Route>

            {/* redirect from not selected language */}
            {noLanguageSelected ? <Redirect to={`/${intl.locale}/`} /> : ''}
            {/* redirect from not existing urls */}
            <Redirect from="/" to={`/${intl.locale}/`} />
        </Switch>
    );
};

export default Routes;
