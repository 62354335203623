import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import LanguageSwitch from '../../LanguageSwitch/LanguageSwitch';
import NavCareer from '../NavCareer/NavCareer';
import NavOetkerGroup from '../NavOetkerGroup/NavOetkerGroup';
import OffCanvas from '../../OffCanvas/OffCanvas';
import messages from './NavMainMobile.messages';
import { minBreakpoint, fromTheme } from '../../../utils';

interface NavMainMobileProps {
    isOpen: boolean;
    toggle: () => void;
}
const StyledOffCanvas = styled(OffCanvas)`
    ${minBreakpoint.md`
        display: none;
    `}
`;

const NavCareerContainer = styled('div')`
    padding: 0 20px;
    background: ${fromTheme('colors', 'white')};
    padding-top: 20px;
    padding-bottom: 20px;
`;

const NavOetkerGroupContainer = styled('div')`
    padding: 30px 20px 20px;
    color: ${fromTheme('colors', 'blue200')};
`;

const NavMainMobile: React.FC<NavMainMobileProps> = ({
    isOpen = false,
    toggle,
}) => {
    return (
        <StyledOffCanvas isOpen={isOpen} onClose={toggle} side="left">
            <NavCareerContainer>
                <NavCareer
                    identifier="mobile"
                    data-is-visible={isOpen}
                    onClick={toggle}
                />
                <LanguageSwitch data-is-visible={isOpen} />
            </NavCareerContainer>

            <NavOetkerGroupContainer>
                <FormattedMessage {...messages.navMobileText} />
                <NavOetkerGroup identifier="mobile" data-is-visible={isOpen} />
            </NavOetkerGroupContainer>
        </StyledOffCanvas>
    );
};

export default NavMainMobile;
