import { defineMessages } from 'react-intl';

const messages = defineMessages({
    button: {
        id: 'component.jobPopup.button',
        defaultMessage: 'component.jobPopup.button',
    },
    taskHeadline: {
        id: 'job.jobContent.task.headline',
        defaultMessage: 'job.jobContent.task.headline',
    },
    profileHeadline: {
        id: 'job.jobContent.profile.headline',
        defaultMessage: 'job.jobContent.profile.headline',
    },
    weOfferHeadline: {
        id: 'job.jobContent.weOffer.headline',
        defaultMessage: 'job.jobContent.weOffer.headline',
    },
    jobContentDefaultTextDrOetker: {
        id: 'job.jobContent.defaultText.drOetker',
        defaultMessage: 'job.jobContent.defaultText.drOetker',
    },
    jobContentDefaultTextOediv: {
        id: 'job.jobContent.defaultText.oediv',
        defaultMessage: 'job.jobContent.defaultText.oediv',
    },
    jobContentDefaultTextOetkerDigital: {
        id: 'job.jobContent.defaultText.oetkerdigital',
        defaultMessage: 'job.jobContent.defaultText.oetkerdigital',
    },
});

export default messages;
