import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'cookieSettings.pageTitle',
        defaultMessage: 'cookieSettings.pageTitle',
    },
    metaDescription: {
        id: 'cookieSettings.metaDescription',
        defaultMessage: 'cookieSettings.metaDescription',
    },
    headline: {
        id: 'cookieSettings.headline',
        defaultMessage: 'cookieSettings.headline',
    },
    section1Headline: {
        id: 'cookieSettings.section1.headline',
        defaultMessage: 'cookieSettings.section1.headline',
    },
    section1Text: {
        id: 'cookieSettings.section1.text',
        defaultMessage: 'cookieSettings.section1.text',
    },
    section2Headline: {
        id: 'cookieSettings.section2.headline',
        defaultMessage: 'cookieSettings.section2.headline',
    },
    section2Text: {
        id: 'cookieSettings.section2.text',
        defaultMessage: 'cookieSettings.section2.text',
    },
    section3Headline: {
        id: 'cookieSettings.section3.headline',
        defaultMessage: 'cookieSettings.section3.headline',
    },
    section3Text: {
        id: 'cookieSettings.section3.text',
        defaultMessage: 'cookieSettings.section3.text',
    },
    button: {
        id: 'cookieSettings.button',
        defaultMessage: 'cookieSettings.button',
    },
});

export default messages;
