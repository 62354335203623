import React, { ReactChild } from 'react';
import {
    FlexContainer,
    FlexItem,
} from '../container/FlexContainer/FlexContainer';
import Picture from '../Picture/Picture';
import styled from 'styled-components';
import { breakpoints } from '../../utils';
import Headline from '../Headline/Headline';

interface PersonCardProps {
    imagePath?: string;
    alt?: string;
    name?: ReactChild;
    text?: ReactChild;
}

const PictureContainer = styled(FlexItem)`
    border-radius: 100%;
    display: block;
    margin: 0 auto 30px;
    max-width: 200px;

    @supports (flex-wrap: wrap) {
        border-radius: 0;
    }

    @media (min-width: ${breakpoints.md}px) {
        margin-right: 30px;
    }
`;

const StyledPicture = styled(Picture)`
    img {
        border-radius: 100%;
        text-align: center;
        margin: 0 auto;

        @media (min-width: ${breakpoints.md}px) {
            text-align: left;
            margin: 0;
        }
    }
`;

const TextContainer = styled(FlexItem)`
    text-align: center;

    @media (min-width: ${breakpoints.md}px) {
        align-self: center;
        text-align: left;
    }
`;

const PersonCard: React.FC<PersonCardProps> = ({
    imagePath,
    alt,
    name,
    text,
    ...props
}) => (
    <FlexContainer columnType="half" {...props}>
        {imagePath && alt && (
            <PictureContainer>
                <StyledPicture imgPathMobile={imagePath} alt={alt} />
            </PictureContainer>
        )}
        <TextContainer>
            <Headline size="smallest">{name}</Headline>
            <p>{text}</p>
        </TextContainer>
    </FlexContainer>
);

export default PersonCard;
