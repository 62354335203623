import styled from 'styled-components';
import { fromTheme, breakpoints } from '../../../utils';

export const Container = styled('div')`
    width: 100%;
    max-width: calc(
        ${fromTheme('grid', 'pageInnerMaxWidth', 'md')} +
            ${fromTheme('grid', 'outerSpace')}
    );
    margin: 0 auto;
    padding: 0 ${fromTheme('grid', 'outerSpace')};
`;

export const PageContainer = styled(Container)`
    max-width: 100%;
    padding: 0;

    @media (min-width: ${breakpoints.md}px) {
        box-shadow: 1px 1px 43px ${fromTheme('colors', 'grey200')};
        max-width: ${fromTheme('grid', 'pageMaxWidth', 'md')};
    }
`;
