import React from 'react';
import { withRouter } from 'react-router-dom';

import JobContentProvider from '../../../provider/JobDataProvider/JobContentProvider';
import JobDataProvider from '../../../provider/JobDataProvider/JobDataProvider';
import PopupProvider from '../../../provider/PopupProvider/PopupProvider';
import { useAnalytics } from '../../../provider/AnalyticsProvider/AnalyticsProvider';
import { useScrollToTop } from '../../../utils';
import { PageContainer } from '../../container/PageContainer/PageContainer';
import CookieBanner from '../../CookieBanner/CookieBanner';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Routes from '../Routes/Routes';

const App: React.FC = ({ ...props }) => {
    const { isCookieBannerVisible } = useAnalytics();

    useScrollToTop();

    return (
        <PageContainer>
            {isCookieBannerVisible && <CookieBanner />}
            <Header />
            <JobContentProvider>
                <PopupProvider>
                    <JobDataProvider>
                        <Routes />
                    </JobDataProvider>
                </PopupProvider>
            </JobContentProvider>
            <Footer />
        </PageContainer>
    );
};

export default withRouter(App);
