import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { fromTheme } from '../../utils';

interface LoaderProps {
    size?: 'regular' | 'tiny';
}

const loaderAnimation = keyframes`
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
`;

const StyledLoader = styled.div<LoaderProps>`
    color: ${fromTheme('colors', 'grey300')};
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;

    &,
    &::before,
    &::after {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        animation-fill-mode: both;
        animation: ${loaderAnimation} 1.8s infinite ease-in-out;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
    }

    &::before {
        left: -3.5em;
        animation-delay: -0.32s;
    }

    &::after {
        left: 3.5em;
    }

    ${(props) =>
        props.size === 'tiny' &&
        css`
            margin: 10px auto;
            font-size: 5px;
        `}
`;

const Loader: FC<LoaderProps> = ({ size = 'regular' }) => {
    return <StyledLoader size={size} />;
};

export default Loader;
