import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'home.pageTitle',
        defaultMessage: 'home.pageTitle',
    },
    metaDescription: {
        id: 'home.metaDescription',
        defaultMessage: 'home.metaDescription',
    },
    headline: {
        id: 'home.headline',
        defaultMessage: 'home.headline',
    },
    section1Teaser1Headline: {
        id: 'home.section1.teaser1.headline',
        defaultMessage: 'home.section1.teaser1.headline',
    },
    section1Teaser1Text: {
        id: 'home.section1.teaser1.text',
        defaultMessage: 'home.section1.teaser1.text',
    },
    section1Teaser2Headline: {
        id: 'home.section1.teaser2.headline',
        defaultMessage: 'home.section1.teaser2.headline',
    },
    section1Teaser2Text: {
        id: 'home.section1.teaser2.text',
        defaultMessage: 'home.section1.teaser2.text',
    },
    section1Teaser3Headline: {
        id: 'home.section1.teaser3.headline',
        defaultMessage: 'home.section1.teaser3.headline',
    },
    section1Teaser3Text: {
        id: 'home.section1.teaser3.text',
        defaultMessage: 'home.section1.teaser3.text',
    },
    section2ContentImage: {
        id: 'home.section2.contentImageAlt',
        defaultMessage: 'home.section2.contentImageAlt',
    },
    section2Headline: {
        id: 'home.section2.headline',
        defaultMessage: 'home.section2.headline',
    },
    section2Text: {
        id: 'home.section2.text',
        defaultMessage: 'home.section2.text',
    },
    button: {
        id: 'home.button',
        defaultMessage: 'home.button',
    },
});

export default messages;
