import React, { useState } from 'react';
import styled from 'styled-components';
import { usePopup } from '../../provider/PopupProvider/PopupProvider';
import { breakpoints, fromTheme, useMedia } from '../../utils';
import Button from '../Button/Button';
import JobListItemPopup from '../JobListItem/Popup/JobListItemPopup';
import JobListAccordion from './JobListAccordion';
import { JobProps } from '../../types/common';

interface JobListProps {
    jobs: JobProps[];
    buttonText: string;
    jobAmountToShow: number;
}

const StyledJobList = styled('ul')`
    margin-left: 0;
    margin-top: 30px;
    border-top: 1px solid ${fromTheme('colors', 'grey500')};
    margin-bottom: 40px;

    @media (min-width: ${breakpoints.md}px) {
        margin-top: 65px;
    }
`;

const StyledListItem = styled('li')`
    list-style-type: none;
`;

const JobList: React.FC<JobListProps> = ({
    jobs,
    buttonText,
    jobAmountToShow,
    ...props
}) => {
    const [amountOfJobsToShow, setAmountOfJobsToShow] =
        useState(jobAmountToShow);

    // show load more button, when not all jobs are displayed
    const showLoadMoreButton = amountOfJobsToShow < jobs.length;
    const { isPopupOpen } = usePopup();
    const isLargerMD = useMedia(`(min-width: ${breakpoints.md}px)`, true);

    return (
        <React.Fragment>
            <StyledJobList {...props}>
                {isLargerMD ? (
                    jobs.slice(0, amountOfJobsToShow).map((job) => {
                        return (
                            <StyledListItem key={'joblistitem' + job.id}>
                                <JobListItemPopup
                                    isOpen={isPopupOpen}
                                    job={job}
                                />
                            </StyledListItem>
                        );
                    })
                ) : (
                    <JobListAccordion
                        jobs={jobs}
                        amountOfJobsToShow={amountOfJobsToShow}
                    />
                )}
            </StyledJobList>

            {showLoadMoreButton && (
                <Button
                    onClick={() => {
                        setAmountOfJobsToShow(
                            amountOfJobsToShow + jobAmountToShow
                        );
                    }}
                    ariaLabel={buttonText}
                >
                    {buttonText}
                </Button>
            )}
        </React.Fragment>
    );
};

export default JobList;
