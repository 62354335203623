import React from 'react';
import Filter from '../../components/Filter/Filter';
import JobSection from '../../components/JobSection/JobSection';
import { useJobData } from '../../provider/JobDataProvider/JobDataProvider';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import PageHead from '../../components/base/PageHead/PageHead';
import messages from './Jobs.messages';

const Jobs = () => {
    const intl = useIntl();
    const { allJobs, getAllJobsAndFilters } = useJobData();
    const { sortedJobs, filter } = getAllJobsAndFilters(allJobs);
    const StyledJobSection = styled(JobSection)`
        min-height: 25vh;
    `;
    const hasFilters =
        Object.keys(filter.level).length > 1 || filter.locations.length > 1;

    return (
        <React.Fragment>
            <PageHead
                pageTitle={intl.formatMessage(messages.pageTitle)}
                metaDescription={intl.formatMessage(messages.metaDescription)}
            />

            {hasFilters && (
                <Filter locations={filter.locations} level={filter.level} />
            )}

            <StyledJobSection
                headlineType="h1"
                jobs={sortedJobs[intl.locale]}
                filterable={true}
                jobAmountToShow={15}
            />
        </React.Fragment>
    );
};

export default Jobs;
