import React, { useEffect, FormEvent, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Level } from '../../types/common';
import { breakpoints, fromTheme } from '../../utils';
import Button from '../Button/Button';
import { Container } from '../container/PageContainer/PageContainer';
import Select from '../Select/Select';
import messages from './Filter.messages';
import { useJobData } from '../../provider/JobDataProvider/JobDataProvider';
import { TranslationContext } from '../../provider/TranslationProvider/TranslationProvider';

interface FilterProps {
    locations: string[];
    level: Level;
}

const FilterContainer = styled('section')`
    background: ${fromTheme('colors', 'blue500')};
    padding: 25px 0;
`;

const StyledFilter = styled(Container)`
    text-align: center;

    @media (min-width: ${breakpoints.md}px) {
        display: flex;
        margin: 0 auto;
        text-align: left;
        justify-content: space-between;
    }
`;

const StyledSelect = styled(Select)<{ label: string; forAttribute: string }>`
    margin-left: 0;
`;

const StyledButton = styled(Button)`
    margin: 0 auto;
    display: block;

    @media (min-width: ${breakpoints.md}px) {
        margin: 0;
    }
`;

const Filter: React.FC<FilterProps> = ({ locations, level, ...props }) => {
    const intl = useIntl();
    const levelNames: string[] = Object.keys(level).map(
        (levelKey) => level[levelKey].name
    );
    const {
        filterJobs,
        activeLocation,
        setActiveLocation,
        activeLevel,
        setActiveLevel,
    } = useJobData();

    const { languageSwitched, setLanguageSwitched } =
        useContext(TranslationContext);

    const onChangeLocationFilter = (e: FormEvent<HTMLSelectElement>) => {
        setActiveLocation(e.currentTarget.value);
    };

    const onChangeLevelFilter = (e: FormEvent<HTMLSelectElement>) => {
        setActiveLevel(e.currentTarget.value);
    };

    const onClick = () => {
        filterJobs(activeLocation, activeLevel);
    };

    useEffect(() => {
        const isLanguageSwitched =
            languageSwitched !== '' && languageSwitched !== undefined;

        // trigger filter reset when language was switched through language switch
        // but only on filter page
        if (isLanguageSwitched) {
            // resets filter
            setActiveLocation('ALL');
            setActiveLevel('ALL');
            filterJobs();

            setLanguageSwitched('');
        }
    }, [
        languageSwitched,
        setLanguageSwitched,
        filterJobs,
        setActiveLevel,
        setActiveLocation,
    ]);

    return (
        <FilterContainer>
            <StyledFilter>
                {locations.length > 1 && (
                    <StyledSelect
                        options={locations}
                        label={intl.formatMessage(messages.locationLabel)}
                        forAttribute="location-select"
                        handleChange={onChangeLocationFilter}
                        value={activeLocation}
                    />
                )}
                {Object.keys(level).length > 0 && (
                    <StyledSelect
                        options={levelNames}
                        label={intl.formatMessage(messages.levelLabel)}
                        forAttribute="level-select"
                        handleChange={onChangeLevelFilter}
                        value={activeLevel}
                    />
                )}
                <StyledButton isGhost={true} onClick={onClick}>
                    <FormattedMessage {...messages.btn} />
                </StyledButton>
            </StyledFilter>
        </FilterContainer>
    );
};

export default Filter;
