import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'coppenrathWiese.pageTitle',
        defaultMessage: 'coppenrathWiese.pageTitle',
    },
    metaDescription: {
        id: 'coppenrathWiese.metaDescription',
        defaultMessage: 'coppenrathWiese.metaDescription',
    },
    headline: {
        id: 'coppenrathWiese.headline',
        defaultMessage: 'coppenrathWiese.headline',
    },
    firstSectionText1: {
        id: 'coppenrathWiese.firstSection.text1',
        defaultMessage: 'coppenrathWiese.firstSection.text1',
    },
    contactHeadline: {
        id: 'coppenrathWiese.contactSection.headline',
        defaultMessage: 'coppenrathWiese.contactSection.headline',
    },
    personCardHeadline: {
        id: 'coppenrathWiese.personCard.headline',
        defaultMessage: 'coppenrathWiese.personCard.headline',
    },
    personCardText: {
        id: 'coppenrathWiese.personCard.text',
        defaultMessage: 'coppenrathWiese.personCard.text',
    },
    section3Headline: {
        id: 'coppenrathWiese.section3.headline',
        defaultMessage: 'coppenrathWiese.section3.headline',
    },
    section3Teaser1Headline: {
        id: 'coppenrathWiese.section3.teaser1.headline',
        defaultMessage: 'coppenrathWiese.section3.teaser1.headline',
    },
    section3Teaser1Text: {
        id: 'coppenrathWiese.section3.teaser1.text',
        defaultMessage: 'coppenrathWiese.section3.teaser1.text',
    },
    section3Teaser1PopupText: {
        id: 'coppenrathWiese.section3.teaser1.popupText',
        defaultMessage: 'coppenrathWiese.section3.teaser1.popupText',
    },
    section3Teaser2Headline: {
        id: 'coppenrathWiese.section3.teaser2.headline',
        defaultMessage: 'coppenrathWiese.section3.teaser2.headline',
    },
    section3Teaser2Text: {
        id: 'coppenrathWiese.section3.teaser2.text',
        defaultMessage: 'coppenrathWiese.section3.teaser2.text',
    },
    section3Teaser2PopupText: {
        id: 'coppenrathWiese.section3.teaser2.popupText',
        defaultMessage: 'coppenrathWiese.section3.teaser2.popupText',
    },
    section3Teaser3Headline: {
        id: 'coppenrathWiese.section3.teaser3.headline',
        defaultMessage: 'coppenrathWiese.section3.teaser3.headline',
    },
    section3Teaser3Text: {
        id: 'coppenrathWiese.section3.teaser3.text',
        defaultMessage: 'coppenrathWiese.section3.teaser3.text',
    },
    section3Teaser3PopupText: {
        id: 'coppenrathWiese.section3.teaser3.popupText',
        defaultMessage: 'coppenrathWiese.section3.teaser3.popupText',
    },
});

export default messages;
