import { defineMessages } from 'react-intl';
import Radeberger from '../src/views/CompanyDetail/Radeberger/Radeberger';
import CoppenrathWiese from '../src/views/CompanyDetail/CoppenrathWiese/CoppenrathWiese';
import OetkerDigital from './views/CompanyDetail/OetkerDigital/OetkerDigital';
import DrOetker from './views/CompanyDetail/DrOetker/DrOetker';
import Oediv from './views/CompanyDetail/Oediv/Oediv';

interface CompanyRoute {
    [company: string]: {
        route: string;
        dvinciId: string;
        component: any;
    };
}

export const companyRoutes: CompanyRoute = {
    ccw: {
        route: '/conditorei-coppenrath-wiese/',
        dvinciId: 'COPPENRATH_WIESE',
        component: CoppenrathWiese,
    },
    od: {
        route: '/oetker-digital/',
        dvinciId: 'OETKER_DIGITAL',
        component: OetkerDigital,
    },
    radebergerGroup: {
        route: '/radeberger-gruppe/',
        dvinciId: 'RADEBERGER',
        component: Radeberger,
    },
    drOetker: {
        route: '/droetker/',
        dvinciId: 'DR_OETKER',
        component: DrOetker,
    },
    oediv: {
        route: '/oediv/',
        dvinciId: 'OEDIV',
        component: Oediv,
    },
};

const routes = defineMessages({
    jobs: {
        id: 'route.jobs',
        defaultMessage: 'route.jobs',
    },
    companyOverview: {
        id: 'route.companyOverview',
        defaultMessage: 'route.companyOverview',
    },
    companyDetail: {
        id: 'route.companyDetail',
        defaultMessage: 'route.companyDetail',
    },
    cookieSettings: {
        id: 'route.cookieSettings',
        defaultMessage: 'route.cookieSettings',
    },
});

export default routes;
