import { defineMessages } from 'react-intl';

const messages = defineMessages({
    navCareerHome: {
        id: 'navCareerHome.home.link.text',
        defaultMessage: 'navCareerHome.home.link.text',
    },
    navCareerJobs: {
        id: 'navCareer.jobs.link.text',
        defaultMessage: 'navCareer.jobs.link.text',
    },
    navCareerGroupCompanies: {
        id: 'navCareer.groupCompanies.link.text',
        defaultMessage: 'navCareer.groupCompanies.link.text',
    },
    navCareerTitle: {
        id: 'navCareer.title',
        defaultMessage: 'navCareer.title',
    },
});

export default messages;
