import { css } from 'styled-components';

import { Breakpoint, breakpoints } from './breakpoints';

type MinBreakpointTemplates = { [K in Breakpoint]?: any };

// Iterate through the sizes and create a minBreakpoint template
export const minBreakpoint = Object.keys(breakpoints).reduce(
    (acc: MinBreakpointTemplates, bp: string) => {
        const breakpoint = bp as Breakpoint;

        acc[breakpoint] = (...args: any) => css`
            @media (min-width: ${breakpoints[breakpoint] / 16}em) {
                // @ts-ignore
                ${css(...args)}
            }
        `;
        return acc;
    },
    {}
) as Required<MinBreakpointTemplates>;
