import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'companyOverview.pageTitle',
        defaultMessage: 'companyOverview.pageTitle',
    },
    metaDescription: {
        id: 'companyOverview.metaDescription',
        defaultMessage: 'companyOverview.metaDescription',
    },
    headline: {
        id: 'companyOverview.headline',
        defaultMessage: 'companyOverview.headline',
    },
    drOetker: {
        id: 'companyOverview.drOetker',
        defaultMessage: 'companyOverview.drOetker',
    },
    radebergerGruppe: {
        id: 'companyOverview.radebergerGruppe',
        defaultMessage: 'companyOverview.radebergerGruppe',
    },
    oediv: {
        id: 'companyOverview.oediv',
        defaultMessage: 'companyOverview.oediv',
    },
    conditoreiCoppenrathWiese: {
        id: 'companyOverview.conditoreiCoppenrathWiese',
        defaultMessage: 'companyOverview.conditoreiCoppenrathWiese',
    },
    oetkerDigital: {
        id: 'companyOverview.oetkerDigital',
        defaultMessage: 'companyOverview.oetkerDigital',
    },
});

export default messages;
