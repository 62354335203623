import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import TranslationProvider from '../../../provider/TranslationProvider/TranslationProvider';
import AnalyticsProvider from '../../../provider/AnalyticsProvider/AnalyticsProvider';
import theme from '../../../theme';
import GlobalStyle from '../GlobalStyle/GlobalStyle';
import App from './App';

const AppContainer: React.FC = () => (
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <AnalyticsProvider>
                <TranslationProvider>
                    <GlobalStyle />
                    <App />
                </TranslationProvider>
            </AnalyticsProvider>
        </ThemeProvider>
    </BrowserRouter>
);

export default AppContainer;
