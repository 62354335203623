import { defineMessages } from 'react-intl';

const messages = defineMessages({
    text: {
        id: 'cookieBanner.content.text',
        defaultMessage: 'cookieBanner.content.text',
    },
    settingsText: {
        id: 'cookieBanner.button.settingsText',
        defaultMessage: 'cookieBanner.button.settingsText',
    },
    ok: {
        id: 'cookieBanner.button.ok',
        defaultMessage: 'cookieBanner.button.ok',
    },
});

export default messages;
