import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import imgPathMobile from '../../assets/images/home-section2-content-image-1x.jpg';
import imgPathTablet from '../../assets/images/home-section2-content-image-2x.jpg';
import stagePathMobile from '../../assets/images/home-stage-x1.jpg';
import stagePathTablet from '../../assets/images/home-stage-x2.jpg';
import Button from '../../components/Button/Button';
import {
    FlexContainer,
    FlexItem,
} from '../../components/container/FlexContainer/FlexContainer';
import { Container } from '../../components/container/PageContainer/PageContainer';
import Section from '../../components/container/Section/Section';
import Filter from '../../components/Filter/Filter';
import Headline from '../../components/Headline/Headline';
import Picture from '../../components/Picture/Picture';
import Stage from '../../components/Stage/Stage';
import { breakpoints } from '../../utils';
import messages from './Home.messages';
import { useJobData } from '../../provider/JobDataProvider/JobDataProvider';
import PageHead from '../../components/base/PageHead/PageHead';
import routes from '../../routes.messages';

const StyledHeadline = styled(Headline)`
    margin-top: 25px;

    @media (min-width: ${breakpoints.md}px) {
        margin-top: 0;
    }
`;

const Home = () => {
    const intl = useIntl();
    const { allJobs, getAllJobsAndFilters } = useJobData();
    const { filter } = getAllJobsAndFilters(allJobs);
    const hasFilters =
        Object.keys(filter.level).length > 1 || filter.locations.length > 1;

    return (
        <>
            <PageHead
                pageTitle={intl.formatMessage(messages.pageTitle)}
                metaDescription={intl.formatMessage(messages.metaDescription)}
            />
            <Stage
                imgPathMobile={stagePathMobile}
                imgPathTablet={stagePathTablet}
                alt={intl.formatMessage(messages.headline)}
            />
            {hasFilters && (
                <Filter locations={filter.locations} level={filter.level} />
            )}
            <Section>
                <Container>
                    <Headline as="h1">
                        <FormattedMessage {...messages.headline} />
                    </Headline>

                    <FlexContainer columnType="third">
                        <FlexItem>
                            <Headline size="small--light">
                                <FormattedMessage
                                    {...messages.section1Teaser1Headline}
                                />
                            </Headline>
                            <p>
                                <FormattedMessage
                                    {...messages.section1Teaser1Text}
                                />
                            </p>
                        </FlexItem>
                        <FlexItem>
                            <Headline size="small--light">
                                <FormattedMessage
                                    {...messages.section1Teaser2Headline}
                                />
                            </Headline>
                            <p>
                                <FormattedMessage
                                    {...messages.section1Teaser2Text}
                                />
                            </p>
                        </FlexItem>
                        <FlexItem>
                            <Headline size="small--light">
                                <FormattedMessage
                                    {...messages.section1Teaser3Headline}
                                />
                            </Headline>
                            <p>
                                <FormattedMessage
                                    {...messages.section1Teaser3Text}
                                />
                            </p>
                        </FlexItem>
                    </FlexContainer>
                </Container>
            </Section>

            <Section background="grey">
                <Container>
                    <FlexContainer columnType="half">
                        <FlexItem>
                            <Picture
                                alt={intl.formatMessage(
                                    messages.section2ContentImage
                                )}
                                imgPathTablet={imgPathTablet}
                                imgPathMobile={imgPathMobile}
                            />
                        </FlexItem>

                        <FlexItem>
                            <StyledHeadline size="small--light">
                                <FormattedMessage
                                    {...messages.section2Headline}
                                />
                            </StyledHeadline>
                            <p>
                                <FormattedMessage {...messages.section2Text} />
                            </p>
                            <Button
                                as={Link}
                                to={`/${intl.locale}/${intl.formatMessage(
                                    routes.companyOverview
                                )}`}
                            >
                                <FormattedMessage {...messages.button} />
                            </Button>
                        </FlexItem>
                    </FlexContainer>
                </Container>
            </Section>
        </>
    );
};

export default Home;
