import React, { createContext, FC, ReactNode, useState } from 'react';
import { IntlProvider } from 'react-intl';
import de from '../../i18n/locales/de.json';
import en from '../../i18n/locales/en.json';
import { useLocation } from 'react-router';

interface TranslationProps {
    children: ReactNode;
}

interface TranslationState {
    setLocale: (language: string) => void;
    setLanguageSwitched: (newLanguage: string) => void;
    languageSwitched: string;
}

export const TranslationContext = createContext<TranslationState>({
    setLocale: () => null,
    setLanguageSwitched: () => null,
    languageSwitched: '',
});

const TranslationProvider: FC<TranslationProps> = ({ children }) => {
    const location = useLocation();
    const urlLanguage = location.pathname.includes('/en/') ? 'en' : 'de';
    const [locale, setLocale] = useState(urlLanguage);
    const messages = locale === 'en' ? en : de;
    const [languageSwitched, setLanguageSwitched] = useState();

    return (
        <TranslationContext.Provider
            value={{ setLocale, languageSwitched, setLanguageSwitched }}
        >
            <IntlProvider
                defaultLocale="de"
                locale={locale}
                messages={messages}
            >
                {children}
            </IntlProvider>
        </TranslationContext.Provider>
    );
};

export default TranslationProvider;
