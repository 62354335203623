import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { Container } from '../../components/container/PageContainer/PageContainer';
import Section from '../../components/container/Section/Section';
import Headline from '../../components/Headline/Headline';
import JobList from '../../components/JobList/JobList';
import { useJobData } from '../../provider/JobDataProvider/JobDataProvider';
import { JobProps } from '../../types/common';
import { fromTheme } from '../../utils';
import Loader from '../Loader/Loader';
import messages from './JobSection.messages';

interface JobSectionProps {
    headlineType: 'h1' | 'h2';
    jobs: JobProps[];
    filterable?: boolean;
    jobAmountToShow: number;
}

const StyledHeadline = styled(Headline)`
    margin-top: 20px;
`;

const ErrorMessage = styled('div')`
    a {
        color: ${fromTheme('colors', 'black')};
        text-decoration: underline;

        &:hover {
            color: ${fromTheme('colors', 'blue500')};
        }
    }
`;

const JobSection: React.FC<JobSectionProps> = ({
    headlineType,
    jobs,
    filterable = false,
    jobAmountToShow,
    ...props
}) => {
    const intl = useIntl();
    const { jobRequestStatus, filteredJobs } = useJobData();

    // filterable defines whether
    // - jobSection will be displayed when no jobs where found
    // - all jobs from a company will be shown
    // > no need to set back the filter on changing between job and company pages

    const jobsToShow =
        filteredJobs !== undefined && filterable ? filteredJobs : jobs;
    const jobAmount = jobsToShow.length;
    const hasErrorOrNoJob = jobRequestStatus === 'error' && jobAmount === 0;
    const isLoadingOrNoJob =
        jobRequestStatus === ('loading' || undefined) && jobAmount === 0;
    const minAmount = filterable ? -1 : 0;
    const jobsLoaded = jobRequestStatus === 'loaded' && jobAmount > minAmount;

    return (
        <React.Fragment>
            {jobs.length > 0 && (
                <Section>
                    <Container>
                        {hasErrorOrNoJob && (
                            <ErrorMessage>
                                <FormattedHTMLMessage {...messages.jobsError} />
                            </ErrorMessage>
                        )}
                        {isLoadingOrNoJob && <Loader />}
                        {jobsLoaded && (
                            <React.Fragment>
                                <StyledHeadline as={headlineType} size="small">
                                    {/* react-intl pluralisation is not supported at the moment */}
                                    {jobAmount === 1 ? (
                                        <FormattedMessage
                                            {...messages.headlineOne}
                                            values={{ amount: jobAmount }}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            {...messages.headlineMany}
                                            values={{ amount: jobAmount }}
                                        />
                                    )}
                                </StyledHeadline>
                                <JobList
                                    {...props}
                                    jobs={jobsToShow}
                                    buttonText={intl.formatMessage(
                                        messages.button
                                    )}
                                    jobAmountToShow={jobAmountToShow}
                                />
                            </React.Fragment>
                        )}
                    </Container>
                </Section>
            )}
        </React.Fragment>
    );
};

export default JobSection;
