import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id: 'oetkerDigital.pageTitle',
        defaultMessage: 'oetkerDigital.pageTitle',
    },
    metaDescription: {
        id: 'oetkerDigital.metaDescription',
        defaultMessage: 'oetkerDigital.metaDescription',
    },
    headline: {
        id: 'oetkerDigital.headline',
        defaultMessage: 'oetkerDigital.headline',
    },
    section1Text1: {
        id: 'oetkerDigital.section1.text1',
        defaultMessage: 'oetkerDigital.section1.text1',
    },
    contactHeadline: {
        id: 'oetkerDigital.contactSection.headline',
        defaultMessage: 'oetkerDigital.contactSection.headline',
    },
    personCard1Headline: {
        id: 'oetkerDigital.personCard1.headline',
        defaultMessage: 'oetkerDigital.personCard1.headline',
    },
    personCard1Text: {
        id: 'oetkerDigital.personCard1.text',
        defaultMessage: 'oetkerDigital.personCard1.text',
    },
    personCard2Headline: {
        id: 'oetkerDigital.personCard2.headline',
        defaultMessage: 'oetkerDigital.personCard2.headline',
    },
    personCard2Text: {
        id: 'oetkerDigital.personCard2.text',
        defaultMessage: 'oetkerDigital.personCard2.text',
    },
    personCard3Headline: {
        id: 'oetkerDigital.personCard3.headline',
        defaultMessage: 'oetkerDigital.personCard3.headline',
    },
    personCard3Text: {
        id: 'oetkerDigital.personCard3.text',
        defaultMessage: 'oetkerDigital.personCard3.text',
    },
});

export default messages;
