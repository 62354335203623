import React, {
    createContext,
    FC,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
    Dispatch,
} from 'react';
import { useIntl } from 'react-intl';
import {
    Company,
    Iso8601,
    JobProps,
    Level,
    RequestStatus,
} from '../../types/common';
import getJobs from './getJobs';
import messages from './JobData.messages';
import routes from '../../routes.messages';
import { useHistory, useLocation } from 'react-router';

interface JobDataProviderProps {
    children: ReactNode;
}

interface SortedJobs {
    [key: string]: JobProps[];
}

interface TargetGroup {
    id: any;
    internalName: string;
    name: string;
}

interface JobDataProviderState {
    allJobs: [];
    filteredJobs: [];
    filterJobs: (location?: string, level?: string) => void;
    getAllJobsAndFilters: (allJobs: []) => {
        sortedJobs: SortedJobs;
        filter: {
            locations: string[];
            level: Level;
        };
    };
    getCompanyJobs: (
        allJobs: [],
        company: Company
    ) => {
        sortedJobs: SortedJobs;
    };
    isANewJob: (creationDate: Iso8601) => boolean;
    getJobLevel: (targetGroups: TargetGroup[]) => string[];
    jobRequestStatus?: RequestStatus;
    activeLocation: string;
    setActiveLocation: Dispatch<any>;
    activeLevel: string;
    setActiveLevel: Dispatch<any>;
}

export const JobDataContext = createContext<JobDataProviderState | undefined>(
    undefined
);

const JobDataProvider: FC<JobDataProviderProps> = ({ children }) => {
    const intl = useIntl();
    const { pathname } = useLocation();
    const history = useHistory();
    const [allJobs, setAllJobs] = useState();
    const [filteredJobs, setFilteredJobs] = useState();
    const [jobRequestStatus, setJobRequestStatus] =
        useState<RequestStatus>('loading');
    const [dvinciJobStructure, setDvinciJobStructure] = useState();
    const [activeLocation, setActiveLocation] = useState();
    const [activeLevel, setActiveLevel] = useState();

    const jobLevelMap: Level = {
        all: {
            id: 'ALL',
            name: intl.formatMessage(messages.allLevel),
        },
        pupils: {
            id: 'PUPILS',
            name: intl.formatMessage(messages.pupils),
        },
        students: {
            id: 'STUDENTS',
            name: intl.formatMessage(messages.students),
        },
        young_professionals: {
            id: 'YOUNG_PROFESSIONALS',
            name: intl.formatMessage(messages.youngProfessionals),
        },
        professionals: {
            id: 'PROFESSIONALS',
            name: intl.formatMessage(messages.professionals),
        },
        managers: {
            id: 'MANAGERS',
            name: intl.formatMessage(messages.managers),
        },
    };

    const sortJobsByCreationDate = (allJobs: JobProps[]) => {
        return [...allJobs].sort(function (a, b) {
            if (a.createdDate > b.createdDate) {
                return -1;
            }
            if (a.createdDate < b.createdDate) {
                return 1;
            }
            return 0;
        });
    };

    const getJobsInCompany = (allJobs: JobProps[], company: Company) => {
        let companyJobs = [];

        for (let i = 0; i < allJobs.length; i++) {
            const job = allJobs[i];

            // get all jobs for the given company
            if (job.company.id === company) {
                companyJobs.push(job);
            }
        }

        return companyJobs;
    };

    const getJobsPerLangAndFilter = (allJobs: JobProps[]) => {
        let sortedJobs: { de: any[]; en: any[] } = { de: [], en: [] },
            filter: {
                locations: string[];
                level: Level;
            } = {
                locations: [intl.formatMessage(messages.allLocations)],
                level: {},
            };

        allJobs.forEach((job) => {
            // seperate jobs into different languages
            if (job.language === 'de') {
                sortedJobs.de.push(job);
            } else {
                sortedJobs.en.push(job);
            }

            // get all different levels
            const levelId = job.level;

            // loop through all levels
            for (let j = 0; j < levelId.length; j++) {
                const levelKey = levelId[j].toLowerCase();

                // set level name if not set yet
                if (filter.level[levelKey] === undefined) {
                    filter.level[levelKey] = jobLevelMap[levelKey];
                }
            }

            // get all different locations
            if (!filter.locations.includes(job.location)) {
                filter.locations.push(job.location);
            }
        });

        return { sortedJobs, filter };
    };

    // get sorted jobs and filters for all companies
    const getAllJobsAndFilters = (
        allJobs: []
    ): { sortedJobs: { [key: string]: any[] }; filter: any } => {
        if (allJobs === undefined) {
            return {
                sortedJobs: { de: [], en: [] },
                filter: { locations: [''], level: {} },
            };
        }

        const sortedJobsByDate = sortJobsByCreationDate(allJobs);
        const { sortedJobs, filter } =
            getJobsPerLangAndFilter(sortedJobsByDate);

        return { sortedJobs, filter };
    };

    // get sorted jobs and filters for company detail page
    const getCompanyJobs = (allJobs: [], company: Company) => {
        // make sure allJobs state was already filled
        // and doesn't block appropriate error or loading message in
        if (allJobs === undefined) {
            return {
                sortedJobs: { de: [], en: [] },
            };
        }

        const sortedJobsByDate = sortJobsByCreationDate(allJobs);
        const companyJobs = getJobsInCompany(sortedJobsByDate, company);
        const { sortedJobs } = getJobsPerLangAndFilter(companyJobs);

        return { sortedJobs };
    };

    // check whether job offer is less than 2 weeks old
    const isANewJob = (creationDate: Iso8601) => {
        const localDateISO = new Date();
        const creationDateISO = new Date(creationDate);
        let isNew = false;

        const daysSinceCreation =
            (localDateISO.getTime() - creationDateISO.getTime()) /
            (1000 * 3600 * 24);

        // job is new when creation day was less than 14 days ago
        if (daysSinceCreation < 14) {
            isNew = true;
        }
        return isNew;
    };

    // get different levels from a job
    const getJobLevel = (targetGroups: TargetGroup[]) => {
        // 'ALL' = default filter Id, to search for all job level
        let level: string[] = ['ALL'];

        // get all levels for a job
        for (let j = 0; j < targetGroups.length; j++) {
            const targetGroup = targetGroups[j];

            // get all different levels
            if (!level.includes(targetGroup.internalName)) {
                level.push(targetGroup.internalName);
            }

            return level;
        }
        return level;
    };

    const transformJobStructure = useCallback((jobs: any) => {
        const jobsCompressed = [];

        for (let i = 0; i < jobs.length; i++) {
            const jobItem = jobs[i];
            const isNew = isANewJob(jobItem.jobOpening.createdDate);
            const level = getJobLevel(jobItem.jobOpening.targetGroups);

            const jobRestructured = {
                title: jobItem.position,
                isNew: isNew,
                company: {
                    id:
                        jobItem.jobOpening.company !== null
                            ? jobItem.jobOpening.company.internalName
                            : '',
                    name: jobItem.jobOpening.company.name,
                },
                location: jobItem.jobOpening.location,
                level: level,
                id: jobItem.id,
                link: jobItem.jobPublicationURL,
                createdDate: jobItem.jobOpening.createdDate,
                language: jobItem.language,
            };

            jobsCompressed.push(jobRestructured);
        }
        return jobsCompressed;
    }, []);

    const filterJobs = (location = 'ALL', levelName = 'ALL') => {
        const { sortedJobs } = getAllJobsAndFilters(allJobs);

        // if user is on homepage > redirect first to job page
        if (pathname === '/' + intl.locale + '/') {
            history.push(`/${intl.locale}/` + intl.formatMessage(routes.jobs));
        }

        // get levelId to filter for
        const levelKey = Object.keys(jobLevelMap).find(
            (key) => jobLevelMap[key].name === levelName
        );

        let levelId = '';
        if (levelKey !== undefined) {
            levelId = jobLevelMap[levelKey].id;
        } else {
            // get all jobs
            levelId = jobLevelMap.all.id;
        }

        // filter by location

        let jobsByLocation = sortedJobs[intl.locale];
        const allEntities = ['Alle Standorte', 'ALL', 'All locations'];
        const isSpecificLocation = allEntities.indexOf(location) === -1;

        if (isSpecificLocation) {
            jobsByLocation = sortedJobs[intl.locale].filter(
                (job: JobProps) => job.location === location
            );
        }

        // filter by levelId
        const checkLevelId = (job: JobProps) => {
            return job.level.includes(levelId);
        };
        const filteredJobs = jobsByLocation.filter(checkLevelId);

        setFilteredJobs(filteredJobs);
    };

    useEffect(() => {
        if (!dvinciJobStructure || dvinciJobStructure.length === 0) {
            // get jobs for job overview list
            getJobs('oetker-gruppe.dvinci.de', intl.locale, true)
                .then((result) => {
                    // error handling - but the API don't provide any helpful information in error cases
                    if (result[0] === 'error') {
                        setJobRequestStatus('error');

                        return false;
                    }

                    setDvinciJobStructure(result);
                    setJobRequestStatus('loaded');
                })
                .catch((error) => {
                    console.info(error);
                });
        }
    }, [intl.locale, dvinciJobStructure]);

    useEffect(() => {
        if (dvinciJobStructure && (!allJobs || allJobs.length === 0)) {
            const allJobs = transformJobStructure(dvinciJobStructure);
            setAllJobs(allJobs);
        }
    }, [dvinciJobStructure, transformJobStructure, allJobs]);

    return (
        <JobDataContext.Provider
            value={{
                allJobs,
                filteredJobs,
                filterJobs,
                getAllJobsAndFilters,
                getCompanyJobs,
                isANewJob,
                getJobLevel,
                jobRequestStatus,
                activeLocation,
                setActiveLocation,
                activeLevel,
                setActiveLevel,
            }}
        >
            {children}
        </JobDataContext.Provider>
    );
};

export const useJobData = () => {
    const context = useContext(JobDataContext);

    if (!context) {
        throw new Error('useJobData must be used within a JobDataProvider');
    }

    return context;
};

export default JobDataProvider;
