import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, fromTheme } from '../../utils';

interface TagProps {
    isNew?: boolean;
    children: ReactNode;
}

const Tag = styled('span')<TagProps>`
    appearance: none;
    user-select: none;
    transition: all 0.15s ease-in-out;
    background-color: ${fromTheme('colors', 'grey500')};
    width: fit-content;
    font-weight: ${fromTheme('fontWeight', 'bold')};
    border-radius: 30px;
    font-size: ${fromTheme('fontSize', 'tag')};
    font-family: ${fromTheme('fontFamily', 'base')};
    padding: 5px 10px;
    text-decoration: none;
    display: block;
    text-align: center;
    word-break: break-word;

    @media (min-width: ${breakpoints.md}px) {
        font-size: ${fromTheme('fontSize', 'tag', 'md')};
    }

    ${(props) =>
        props.isNew &&
        css`
            border-radius: 4px;
            background-color: ${fromTheme('colors', 'blue500')};
            text-transform: uppercase;
            padding: 3px 6px 1px;
            color: ${fromTheme('colors', 'white')};
            display: inline-block;
        `};
`;

export default Tag;
