import styled, { css } from 'styled-components';

import { breakpoints, isFeatureSupport, fromTheme } from '../../../utils';

export type FlexContainerType = 'half' | 'third' | '2-1';
export interface FlexContainerProps {
    children: any;
    columnType?: FlexContainerType;
    reverseDesktop?: boolean;
}

const FlexContainer = styled('div')<FlexContainerProps>`
    @media (min-width: ${breakpoints.md}px) {
        display: flex;
        flex-wrap: wrap;

        > * {
            overflow: hidden;
            vertical-align: top;
        }

        ${(props) =>
            props.columnType === 'half' &&
            css`
                @media (min-width: ${breakpoints.md}px) {
                    > ${FlexItem} {
                        width: ${fromTheme('grid', 'columnHalfWidth', 'md')};

                        &:nth-child(2n + 1) {
                            margin-right: ${fromTheme(
                                'grid',
                                'columnHalfSpacing',
                                'md'
                            )};
                        }
                    }
                }
            `};

        ${(props) =>
            props.columnType === 'third' &&
            css`
                @media (min-width: ${breakpoints.md}px) {
                    > ${FlexItem} {
                        width: ${fromTheme('grid', 'columnThirdWidth', 'md')};

                        + ${FlexItem} {
                            margin-left: ${fromTheme(
                                'grid',
                                'columnThirdSpacing',
                                'md'
                            )};
                        }

                        &:nth-child(3n + 1) {
                            margin-left: 0;
                        }
                    }
                }
            `}

        ${(props) =>
            props.columnType === '2-1' &&
            css`
                @media (min-width: ${breakpoints.md}px) {
                    > ${FlexItem} {
                        flex: 2;

                        &:nth-child(2n) {
                            flex: 1;
                            margin-left: ${fromTheme(
                                'grid',
                                'columnHalfSpacing',
                                'md'
                            )};
                        }
                    }
                }
            `};

        ${(props) =>
            props.columnType === '2-1' &&
            props.reverseDesktop &&
            css`
                > ${FlexItem} {
                    &:first-child {
                        order: 2;
                        flex: 1;
                        margin-left: ${fromTheme(
                            'grid',
                            'columnHalfSpacing',
                            'md'
                        )};
                    }

                    &:last-child {
                        order: 1;
                        flex: 2;
                        margin-left: 0;
                    }
                }
            `}
    }
`;

const FlexItem = isFeatureSupport('pictureTag')
    ? styled('div')``
    : styled('div')`
          @media (min-width: ${breakpoints.md}px) {
              flex: 1;

              &:not(:last-child) {
                  margin-right: 3%;
              }
          }
      `;

export { FlexContainer, FlexItem };
