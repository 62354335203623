import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ListArrow } from '../../../assets/icons/list-arrow.svg';
import { useJobContent } from '../../../provider/JobDataProvider/JobContentProvider';
import { usePopup } from '../../../provider/PopupProvider/PopupProvider';
import { JobProps } from '../../../types/common';
import { breakpoints, fromTheme } from '../../../utils';
import {
    FlexContainer,
    FlexItem,
} from '../../container/FlexContainer/FlexContainer';
import Tag from '../../Tag/Tag';
import JobItemDescription from '../JobItemDescription';

// this is a variant of the job list item
interface JobListItemPopupProps {
    isOpen: boolean;
    job: JobProps;
}

const StyledFlexContainer = styled(FlexContainer)`
    border-bottom: 1px solid ${fromTheme('colors', 'grey500')};
    font-size: ${fromTheme('fontSize', 'headlineSmall')};
    padding: 25px 0 25px 20px;
    list-style-type: none;
    position: relative;
    cursor: pointer;

    @media (min-width: ${breakpoints.md}px) {
        padding-right: 25px;
        font-size: ${fromTheme('fontSize', 'headlineSmall', 'md')};
    }
`;

const ListArrowContainer = styled('span')<{ isFlipped: boolean }>`
    display: block;
    position: absolute;
    left: 0;
    top: 23px;
    transition: transform 0.2s;
    transform: rotate(-90deg);

    ${(props) =>
        props.isFlipped &&
        css`
            transform: rotate(0deg);
        `}
`;

const TitleContainer = styled(FlexItem)`
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 0;
    }
`;

const Title = styled('span')`
    margin-right: 15px;

    @media (min-width: ${breakpoints.md}px) {
        margin-right: 25px;
    }
`;

const TagContainer = styled(FlexContainer)`
    margin-right: 3px;
    display: flex;

    > div {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

const PopupContent = styled('div')`
    position: relative;
`;

const JobListItemPopup: React.FC<JobListItemPopupProps> = ({
    job,
    isOpen,
    ...props
}) => {
    const { togglePopup, popupContentId, isPopupOpen } = usePopup();
    const { getJobContent } = useJobContent();
    const hasCompanyName =
        job.company !== undefined && job.company.name !== undefined;

    const popupContent = (
        <PopupContent>
            <JobListItemPopup
                // to close popup on text header click
                isOpen={true}
                job={job}
            />
            <JobItemDescription
                href={job.link}
                isSmallHeight={true}
                jobTitle={job.title}
            />
        </PopupContent>
    );

    return (
        <StyledFlexContainer
            columnType="2-1"
            {...props}
            // request specific jobContent when popup will be opened
            onClick={() => {
                if (!isPopupOpen) {
                    getJobContent(job);
                }
                togglePopup(popupContent, job.id);
            }}
            data-testid="joblistitem-popup"
        >
            <TitleContainer>
                <ListArrowContainer isFlipped={job.id === popupContentId}>
                    <ListArrow />
                </ListArrowContainer>
                <Title>{job.title}</Title>
                {job.isNew && <Tag isNew>New</Tag>}
            </TitleContainer>
            <FlexItem>
                <TagContainer columnType="half">
                    <FlexItem>
                        {hasCompanyName && <Tag>{job.company.name}</Tag>}
                    </FlexItem>
                    <FlexItem>
                        <Tag>{job.location}</Tag>
                    </FlexItem>
                </TagContainer>
            </FlexItem>
        </StyledFlexContainer>
    );
};

export default JobListItemPopup;
