import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { useJobContent } from '../../provider/JobDataProvider/JobContentProvider';
import { breakpoints, fromTheme } from '../../utils';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';
import messages from './JobItemDescription.messages';

interface JobItemDescriptionProps {
    href: string;
    isSmallHeight?: boolean;
    jobTitle: string;
}

const DynamicContent = styled('div')<{ isSmallHeight: boolean }>`
    overflow: scroll;
    pointer-events: all;
    margin-top: 20px;
    padding-bottom: 50px;

    @media (min-width: ${breakpoints.sm}px) {
        margin: 20px 10px 0;
    }

    @media (min-width: ${breakpoints.md}px) {
        margin: 35px 20px;
        overflow: scroll;
    }

    ul,
    p {
        margin-bottom: 20px;
    }

    ul {
        margin-left: 40px;
    }

    ${(props) =>
        props.isSmallHeight &&
        css`
            @media (min-height: 568px) and (min-width: ${breakpoints.md}px) {
                height: ${window.innerHeight - 265}px;
            }
        `}
`;

const StyledLink = styled(Button)`
    margin-top: 30px;
    margin-bottom: 0;
    width: 100%;
    text-align: center;

    @media (min-width: ${breakpoints.md}px) {
        margin-top: 90px;
        width: auto;
        text-align: left;
    }
`;

const TextSection = styled('div')`
    h3 {
        font-weight: ${fromTheme('fontWeight', 'regular')};
        font-size: ${fromTheme('fontSize', 'base', 'md')};
        margin-bottom: ${fromTheme('grid', 'outerSpace')};
    }
`;

const JobItemDescription: React.FC<JobItemDescriptionProps> = ({
    href,
    isSmallHeight = false,
    jobTitle,
    ...props
}) => {
    const { jobContent, contentRequestStatus } = useJobContent();
    const JobTemplate: React.FC = () => {
        const isLoading = contentRequestStatus === 'loading' || undefined;
        const isJobsLoaded =
            jobContent !== '' && contentRequestStatus === 'loaded';
        const hasJobsIntroText = jobContent && jobContent.intro;
        const noJobContentAvailable = contentRequestStatus === 'error';

        return (
            <TextSection>
                {isLoading && <Loader />}
                {isJobsLoaded && (
                    <div>
                        {hasJobsIntroText && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: jobContent.intro,
                                }}
                            />
                        )}

                        {jobContent.tasks && (
                            <React.Fragment>
                                <h3>
                                    <FormattedMessage
                                        {...messages.taskHeadline}
                                    />
                                </h3>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: jobContent.tasks,
                                    }}
                                />
                            </React.Fragment>
                        )}

                        {jobContent.profile && (
                            <React.Fragment>
                                <h3>
                                    <FormattedMessage
                                        {...messages.profileHeadline}
                                    />
                                </h3>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: jobContent.profile,
                                    }}
                                />
                            </React.Fragment>
                        )}

                        {jobContent.weOffer && (
                            <React.Fragment>
                                <h3>
                                    <FormattedMessage
                                        {...messages.weOfferHeadline}
                                    />
                                </h3>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: jobContent.weOffer,
                                    }}
                                />
                            </React.Fragment>
                        )}
                    </div>
                )}

                {noJobContentAvailable ? (
                    href.includes('oetkerdigital') ? (
                        <FormattedHTMLMessage
                            {...messages.jobContentDefaultTextOetkerDigital}
                        />
                    ) : (
                        <FormattedHTMLMessage
                            {...messages.jobContentDefaultTextDrOetker}
                        />
                    )
                ) : (
                    false
                )}
            </TextSection>
        );
    };

    return (
        <DynamicContent isSmallHeight={isSmallHeight}>
            <JobTemplate />
            {href && jobTitle && (
                <StyledLink
                    as="a"
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-title={jobTitle}
                >
                    <FormattedMessage {...messages.button} />
                </StyledLink>
            )}
        </DynamicContent>
    );
};

export default JobItemDescription;
