import React from 'react';
import styled from 'styled-components';
import { fromTheme, breakpoints } from '../../utils';
import Headline from '../Headline/Headline';

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
    headline: JSX.Element | string;
    message?: JSX.Element | string;
    id: string;
}

interface RadioButtonPropsInitialsed extends RadioButtonProps {}

const StyledRadioButton = styled('span')`
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;
`;

const HiddenInput = styled('input')`
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked + ${StyledRadioButton}::before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: ${fromTheme('colors', 'black')};
        border-radius: 50%;
        transform: translate(5px, 5px);
    }
`;

const StyledLabel = styled('label')`
    @media (min-width: ${breakpoints.md}px) {
        flex: 1;

        &:not(:last-child) {
            margin-right: 3%;
        }
    }
`;

const OptionContent = styled('div')`
    cursor: pointer;
    margin-left: 34px;
    margin-top: -28px;
`;

const RadioButton: React.FC<RadioButtonProps> = ({
    headline,
    message,
    id,
    onChange,
    name,
    value,
    checked,
    ...props
}: RadioButtonProps) => (
    <StyledLabel htmlFor={id} key={id}>
        <HiddenInput
            type="radio"
            onChange={onChange}
            name={name}
            value={value}
            checked={checked}
            id={id}
            {...props}
        />
        <StyledRadioButton />
        <OptionContent>
            {headline ? (
                <Headline size="small">{headline}</Headline>
            ) : undefined}
            {message ? <p>{message}</p> : undefined}
        </OptionContent>
    </StyledLabel>
);

export default RadioButton;
