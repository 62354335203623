import { defineMessages } from 'react-intl';

const messages = defineMessages({
    btn: {
        id: 'filter.btn',
        defaultMessage: 'filter.btn',
    },
    locationLabel: {
        id: 'filter.select.location.label',
        defaultMessage: 'filter.select.location.label',
    },
    levelLabel: {
        id: 'filter.select.level.label',
        defaultMessage: 'filter.select.level.label',
    },
});

export default messages;
