import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { fromTheme, breakpoints } from '../../utils';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl';
import { Container } from '../container/PageContainer/PageContainer';
import Button from '../Button/Button';
import routes from '../../routes.messages';
import messages from './CookieBanner.messages';
import { useAnalytics } from '../../provider/AnalyticsProvider/AnalyticsProvider';

const Banner = styled('div')`
    background-color: ${fromTheme('colors', 'grey600')};
    color: ${fromTheme('colors', 'white')};
    font-weight: ${fromTheme('fontWeight', 'bold')};
    z-index: 1;
    position: relative;

    a {
        color: ${fromTheme('colors', 'white')};
    }
`;

const StyledContainer = styled(Container)`
    position: relative;
`;

const Content = styled('div')`
    max-width: 85%;
    padding: 30px 0 5px;

    @media (min-width: ${breakpoints.sm}px) {
        max-width: 80%;
    }

    @media (min-width: ${breakpoints.md}px) {
        max-width: 70%;
    }
`;

const StyledButton = styled(Button)`
    margin-right: 30px;

    &::before {
        background-color: ${fromTheme('colors', 'grey400')};
    }
`;

const StyledButtonAbsolute = styled(Button)`
    position: absolute;
    right: 10px;
    padding: 8px 8px 4px 9px;
    top: 30px;

    &::before {
        background-color: ${fromTheme('colors', 'grey600')};
    }

    @media (min-width: ${breakpoints.md}px) {
        right: 20px;
    }
`;

const CookieBanner = () => {
    const intl = useIntl();

    const { closeBanner } = useAnalytics();
    const history = useHistory();

    const redirectToCookieSettings = () => {
        closeBanner();
        history.push(
            `/${intl.locale}/` + intl.formatMessage(routes.cookieSettings)
        );
    };

    return (
        <Banner>
            <StyledContainer>
                <Content>
                    <p>
                        <FormattedHTMLMessage {...messages.text} />
                    </p>
                    <StyledButton onClick={redirectToCookieSettings}>
                        <FormattedMessage {...messages.settingsText} />
                    </StyledButton>

                    <StyledButton onClick={closeBanner}>
                        <FormattedMessage {...messages.ok} />
                    </StyledButton>
                </Content>
                <StyledButtonAbsolute onClick={closeBanner}>
                    <CloseIcon />
                </StyledButtonAbsolute>
            </StyledContainer>
        </Banner>
    );
};

export default CookieBanner;
