const cookieTier = ['tier_1', 'tier_2', 'tier_3'];

export const defaultCookieValue = 3;

export const getCookieValues = (key: string): string[] =>
    document.cookie.split(';').filter((item) => item.includes(key));

export const hasCookie = (): boolean => {
    const filteredValues = getCookieValues('cookieTier');

    const cookieTierValues: any = filteredValues.filter((item) => {
        const value = item.split('=').pop();

        if (value) {
            return cookieTier.indexOf(value) > -1;
        }

        return false;
    });

    return cookieTierValues.length > 0;
};

export const setCookie = (value: number, lifetimeInDays: number = 365) => {
    const d = new Date();

    d.setTime(d.getTime() + lifetimeInDays * 24 * 60 * 60 * 1000);
    document.cookie = `cookieTier=tier_${value};expires=${d.toUTCString()}`;
};

export const setCookieTier = (tier: number) => {
    const currentTier = getCookieTier();

    if (currentTier && tier === parseInt(currentTier)) {
        return;
    }

    removeTierCookie();
    setCookie(tier);
};

export const getCookieTier = (): string | undefined => {
    const values = getCookieValues('cookieTier');

    if (values && values.length > 0) {
        return values[0].split('_').pop();
    }
};

export const removeTierCookie = () => {
    const cookies = document.cookie.split('; ');
    const tierCookie = cookies.find((cookie) =>
        cookie.includes('cookieTier=tier_')
    );
    const d = window.location.hostname.split('.');

    while (d.length > 0 && tierCookie !== undefined) {
        const cookieBase =
            encodeURIComponent(tierCookie.split('=')[0]) +
            '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
            d.join('.') +
            ' ;path=';
        const p = window.location.pathname.split('/');
        document.cookie = cookieBase + '/';

        while (p.length > 0) {
            document.cookie = cookieBase + p.join('/');
            p.pop();
        }
        d.shift();
    }
};

export const getActualCookie = () => {
    // get tier from cookie
    const tierValue = getCookieTier();

    // actual cookie tier number
    const actualCookieValue = tierValue
        ? parseInt(tierValue)
        : defaultCookieValue;

    return actualCookieValue;
};

// get tier from cookie
const tierValue = getCookieTier();

// actual cookie tier number
export const actualCookieValue = tierValue
    ? parseInt(tierValue)
    : defaultCookieValue;

export const track = (ec: string = '') => {
    (window as any).dataLayer.push({
        event: 'virtualPageView',
        eventValues: {
            ea: 'click',
            ec: ec,
            el: '',
        },
    });
};
